import React, { useState, useEffect } from 'react'
import '../../styleLanding.css'
import NewHeader from '../Header/NewHeader'
import { CLIENT_PROPAGILE_URL } from '../../Constants/serverConfig';
import Footer from '../Footer';
const LandingPage = ({ isSpLoggedIn }) => {

    const [showPhasePopup, setShowPhasePopup] = useState(false);
    const [phaseId, setPhaseId] = useState(0);

    useEffect(() => {
        if (isSpLoggedIn) {
            window.location.href = '/vendor/dashboard';
        }
    }, [])


    const handleShowPhaseModal = (phaseId = 0) => {

        setPhaseId(phaseId);
        setShowPhasePopup(!showPhasePopup);
    }

    const goToStart = () => {
        window.location.href = "/workflow";
    }

    return (
        <div className='landing-body'>
            <NewHeader />
            <div className={`modal small-popup ` + (showPhasePopup ? 'is-active' : '')}>
                <div className="modal-background" />
                <div className={"modal-content modal-content-landing"}>
                    <section className={"modal-landing-body"}>
                        <button
                            onClick={handleShowPhaseModal}
                            className="close-modal-landing"
                        >X</button>
                        {
                            phaseId === 1 &&
                            <div className='p-5'>
                                <h2 className="d-none d-md-block" style={{ fontFamily: "Inter, serif", fontSize: "42px", fontWeight: "600", lineHeight: 1.2 }}>Feasibility Phase Frameworks</h2>
                                <h2 className="d-block d-md-none" style={{ fontFamily: "Inter, serif", fontSize: "38px", fontWeight: "600", lineHeight: 1.2 }}>Feasibility Phase Frameworks</h2>
                                <ul className='framework-ul'>
                                    <li>F1: Define project vision, objectives, and timelines.</li>
                                    <li>F2: Align owner goals with feasibility outcomes.</li>
                                    <li>F3: Conduct thorough site and zoning analysis.</li>
                                    <li>F4: Evaluate demolition and deconstruction potential.</li>
                                    <li>F5: Identify key risks and create contingency plans.</li>
                                    <li>F6: Outline preliminary permitting strategies for compliance.</li>
                                    <li>F7: Set measurable sustainability and energy goals.</li>
                                    <li>F8: Establish initial BIM workflows for integration.</li>
                                    <li>F9: Select consultants and contractors strategically.</li>
                                    <li>F10: Assess modular construction feasibility to enhance flexibility.</li>
                                    <li>F11: Plan for adaptive reuse and historical preservation opportunities.</li>
                                    <li>F12: Develop funding strategies and financial plans for stability.</li>
                                </ul>
                            </div>
                        }
                        {
                            phaseId === 2 &&
                            <div className='p-5'>
                                <h2 className="d-none d-md-block" style={{ fontFamily: "Inter, serif", fontSize: "42px", fontWeight: "600", lineHeight: 1.2 }}>Planning Phase Frameworks</h2>
                                <h2 className="d-block d-md-none" style={{ fontFamily: "Inter, serif", fontSize: "38px", fontWeight: "600", lineHeight: 1.2 }}>Planning Phase Frameworks</h2>
                                <ul className='framework-ul'>
                                    <li>P1: Define design and engineering workflows.</li>
                                    <li>P2: Develop procurement strategies for seamless execution.</li>
                                    <li>P3: Prepare construction setup plans for smooth transitions.</li>
                                    <li>P4: Expand and enhance risk management frameworks.</li>
                                    <li>P5: Optimize and coordinate BIM workflows.</li>
                                    <li>P6: Ensure sustainability compliance across all phases.</li>
                                    <li>P7: Manage long-lead item procurement to mitigate delays.</li>
                                    <li>P8: Assemble teams and establish project phasing strategies.</li>
                                    <li>P9: Leverage value engineering to optimize costs and resources.</li>
                                    <li>P10: Develop digital collaboration strategies for efficient communication.</li>
                                    <li>P11: Plan mockups and prototypes for validation.</li>
                                    <li>P12: Address demolition and hazardous materials compliance.</li>
                                    <li>P13: Align lifecycle cost analysis with project objectives.</li>
                                    <li>P14: Prepare for system commissioning and operational readiness.</li>
                                </ul>
                            </div>
                        }
                        {
                            phaseId === 3 &&
                            <div className='p-5'>
                                <h2 className="d-none d-md-block" style={{ fontFamily: "Inter, serif", fontSize: "42px", fontWeight: "600", lineHeight: 1.2 }}>Construction Phase Frameworks</h2>
                                <h2 className="d-block d-md-none" style={{ fontFamily: "Inter, serif", fontSize: "38px", fontWeight: "600", lineHeight: 1.2 }}>Construction Phase Frameworks</h2>
                                <ul className='framework-ul'>
                                    <li>C1: Manage construction operations with precision.</li>
                                    <li>C2: Oversee quality assurance and control for consistency.</li>
                                    <li>C3: Implement robust risk and safety protocols.</li>
                                    <li>C4: Coordinate subcontractors and vendors efficiently.</li>
                                    <li>C5: Monitor and control construction progress in real-time.</li>
                                    <li>C6: Proactively manage change orders and cost adjustments.</li>
                                    <li>C7: Ensure compliance with environmental regulations.</li>
                                    <li>C8: Monitor insurance and bonding requirements.</li>
                                    <li>C9: Integrate advanced technology during construction phases.</li>
                                    <li>C10: Implement prefabrication strategies for faster delivery.</li>
                                    <li>C11: Plan temporary works to support construction needs.</li>
                                    <li>C12: Manage submittals and shop drawings seamlessly.</li>
                                    <li>C13: Utilize specialized construction techniques for innovation.</li>
                                    <li>C14: Conduct comprehensive system-level commissioning.</li>
                                </ul>
                            </div>
                        }
                        {
                            phaseId === 4 &&
                            <div className='p-5'>
                                <h2 className="d-none d-md-block" style={{ fontFamily: "Inter, serif", fontSize: "42px", fontWeight: "600", lineHeight: 1.2 }}>Handover Phase Frameworks</h2>
                                <h2 className="d-block d-md-none" style={{ fontFamily: "Inter, serif", fontSize: "38px", fontWeight: "600", lineHeight: 1.2 }}>Handover Phase Frameworks</h2>
                                <ul className='framework-ul'>
                                    <li>H1: Finalize project handover and transition processes.</li>
                                    <li>H2: Validate sustainability certifications for compliance.</li>
                                    <li>H3: Manage warranties and post-occupancy evaluations.</li>
                                    <li>H4: Develop robust maintenance and operations protocols.</li>
                                    <li>H5: Plan for disaster recovery and emergency readiness.</li>
                                    <li>H6: Integrate lessons learned for future projects.</li>
                                    <li>H7: Promote circular economy practices and material reuse.</li>
                                    <li>H8: Conduct detailed post-occupancy evaluations.</li>
                                    <li>H9: Perform final commissioning for operational success.</li>
                                    <li>H10: Establish efficient digital collaboration and data management workflows.</li>
                                    <li>H11: Train occupants to ensure readiness and smooth operations.</li>
                                </ul>
                            </div>
                        }
                    </section>

                </div>
            </div>

            <section className="section-landing sl-1" style={{ backgroundColor: '#eaeaeaea', padding: '100px 0px' }}>
                <div class="container">

                    <h1 className="d-none d-md-block" style={{ fontFamily: "Inter, serif", fontSize: "96px", fontWeight: "600" }}>Lead with Confidence</h1>
                    <h1 className="d-block d-md-none" style={{ fontFamily: "Inter, serif", fontSize: "70px", fontWeight: "600" }}>Lead with Confidence</h1>

                    <h1 className='d-none d-md-block font-green' style={{ fontFamily: "Inter, serif", fontSize: "96px", fontWeight: "600" }}>Build Smarter</h1>
                    <h1 className='d-block d-md-none font-green' style={{ fontFamily: "Inter, serif", fontSize: "60px", fontWeight: "600" }}>Build Smarter</h1>

                    <p className='section-one-paraph'>Imagine a clear, customizable roadmap guiding your project from start to finish. With real-time tools and proven frameworks, you’ll avoid costly mistakes, stay aligned with your team, and deliver results faster. Simplify processes, stay in control, and lead every project with clarity and confidence.</p>
                    <br />
                    <button class="cta-button" style={{ letterSpacing: "1px" }} onClick={goToStart}>Start Your Project</button>
                </div>
            </section>
            <section className="section-landing " style={{ padding: '150px 0px' }}>
                <div class="container">
                    <h2 className="d-none d-md-block" style={{ fontFamily: "Inter, serif", fontSize: "52px", fontWeight: "600", lineHeight: 1.2 }}>Define the <span className='font-green' style={{ fontSize: "62px" }}>'What,'</span> <span className='font-green' style={{ fontSize: "62px" }}>'Who,'</span> and <span className='font-green' style={{ fontSize: "62px" }}>'How'</span> <br />in Construction</h2>
                    <h2 className="d-block d-md-none" style={{ fontFamily: "Inter, serif", fontSize: "52px", fontWeight: "600", lineHeight: 1.1 }}>Define the <span className='font-green' >'What,'</span> <span className='font-green'>'Who,'</span> and <span className='font-green' >'How'</span> <br />in Construction</h2>
                    <br></br>
                    <br></br>
                    <p style={{ fontFamily: "Inter, serif", fontSize: "28px" }}>From planning to execution, PropAgile simplifies project management by helping you define tasks, outline responsibilities, and track progress seamlessly. Stay on top of every phase with tailored, cloud-based checklists designed to streamline decisions, eliminate missed details, and reduce confusion.</p>

                </div>
            </section>
            <section className="section-landing" style={{ backgroundColor: '#eaeaeaea', padding: '150px 0px' }}>
                <div className="container">
                    <h2 className="d-none d-md-block" style={{ fontFamily: "Inter, serif", fontSize: "52px", fontWeight: "700" }}>What Makes <span className='font-green'>PropAgile</span> Powerful</h2>
                    <h2 className="d-block d-md-none" style={{ fontFamily: "Inter, serif", fontSize: "52px", fontWeight: "700", lineHeight: 1.1 }}>What Makes <span className='font-green'>PropAgile</span> Powerful</h2>
                    <img className='img-features' src="./images/plan-design.png" alt="propagile plan design" width={800} />
                    <p style={{ fontFamily: "Inter, serif", fontSize: "28px" }}>PropAgile combines powerful features with practical benefits to simplify construction management and help you achieve better results, faster.</p>

                    <div className='container-card '>
                        {/* marge altura */}
                        <div className='border card-features bg-light '>
                            <div className='d-flex flex-row align-items-center justify-content-center'>
                                <img src="./images/features.png" alt="propagile features" style={{ height: '28px', width: '28px' }} />
                                <h3 className="" style={{ margin: '0px 0px 0px 5px', fontFamily: "Inter, serif", fontSize: "30px", fontWeight: "600" }}>Key Features</h3>
                            </div>
                            <div>
                                <div className='d-flex flex-column justify-content-start align-items-start mt-4'>
                                    <span className="d-none d-md-block" style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "20px", fontWeight: "600", color: "#1aa354" }}>Built on Industry Standards:</span>
                                    <span className="d-block d-md-none" style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "24px", fontWeight: "600", color: "#1aa354" }}>Built on Industry Standards:</span>
                                    <p className="d-none d-md-block" style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "16px" }}>Frameworks aligned with CSI UniFormat, MasterFormat, AIA guidelines, and BIM LOD standards for consistency and compliance.</p>
                                    <p className="d-block d-md-none" style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "18px" }}>Frameworks aligned with CSI UniFormat, MasterFormat, AIA guidelines, and BIM LOD standards for consistency and compliance.</p>
                                </div>
                                <div className='d-flex flex-column justify-content-start align-items-start'>
                                    <span className="d-none d-md-block" style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "20px", fontWeight: "600", color: "#1aa354" }}>Customizable Workflows:</span>
                                    <span className="d-block d-md-none" style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "24px", fontWeight: "600", color: "#1aa354" }}>Customizable Workflows:</span>
                                    <p className="d-none d-md-block" style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "16px" }}>Tailor Work Breakdown Structures (WBS) to fit your project’s unique needs and integrate seamlessly with tools like Procore, Revit, and Microsoft Project.</p>
                                    <p className="d-block d-md-none" style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "18px" }}>Tailor Work Breakdown Structures (WBS) to fit your project’s unique needs and integrate seamlessly with tools like Procore, Revit, and Microsoft Project.</p>
                                </div>
                                <div className='d-flex flex-column justify-content-start align-items-start'>
                                    <span className="d-none d-md-block" style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "20px", fontWeight: "600", color: "#1aa354" }}>Real-Time Management:</span>
                                    <span className="d-block d-md-none" style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "24px", fontWeight: "600", color: "#1aa354" }}>Real-Time Management:</span>
                                    <p className="d-none d-md-block" style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "16px" }}>Use cloud-based checklists to track progress, edit on the go, and manage evolving project needs with ease.</p>
                                    <p className="d-block d-md-none" style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "18px" }}>Use cloud-based checklists to track progress, edit on the go, and manage evolving project needs with ease.</p>
                                </div>
                            </div>
                        </div>
                        <div className='border card-features bg-light'>
                            <div className='d-flex flex-row align-items-center justify-content-center'>
                                <img src="./images/star-check.png" alt="propagile features" style={{ height: '28px', width: '28px' }} />
                                <h3 className="" style={{ margin: '0px 0px 0px 5px', fontFamily: "Inter, serif", fontSize: "30px", fontWeight: "600" }}>How It Benefits You</h3>
                            </div>
                            <div>
                                <div className='d-flex flex-column justify-content-start align-items-start mt-4'>

                                    <span className="d-none d-md-block" style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "20px", fontWeight: "600", color: "#1aa354" }}>Clear Accountability:</span>
                                    <span className="d-block d-md-none" style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "24px", fontWeight: "600", color: "#1aa354" }}>Clear Accountability:</span>
                                    <p className="d-none d-md-block" style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "16px" }}>Define scopes, milestones, and responsibilities with precision to eliminate confusion and ensure team alignment.</p>
                                    <p className="d-block d-md-none" style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "18px" }}>Define scopes, milestones, and responsibilities with precision to eliminate confusion and ensure team alignment.</p>
                                </div>
                                <div className='d-flex flex-column justify-content-start align-items-start'>
                                    <span className="d-none d-md-block" style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "20px", fontWeight: "600", color: "#1aa354" }}>Proactive Risk Management:</span>
                                    <span className="d-block d-md-none" style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "24px", fontWeight: "600", color: "#1aa354" }}>Proactive Risk Management:</span>
                                    <p className="d-none d-md-block" style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "16px" }}>Identify risks and opportunities early to refine budgets, timelines, and benchmarks.</p>
                                    <p className="d-block d-md-none" style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "18px" }}>Identify risks and opportunities early to refine budgets, timelines, and benchmarks.</p>
                                </div>
                                <div className='d-flex flex-column justify-content-start align-items-start'>
                                    <span className="d-none d-md-block" style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "20px", fontWeight: "600", color: "#1aa354" }}>Streamlined Collaboration:</span>
                                    <span className="d-block d-md-none" style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "24px", fontWeight: "600", color: "#1aa354" }}>Streamlined Collaboration:</span>
                                    <p className="d-none d-md-block" style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "16px" }}>Reduce errors, delays, and miscommunication by aligning teams with standardized processes.</p>
                                    <p className="d-block d-md-none" style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "18px" }}>Reduce errors, delays, and miscommunication by aligning teams with standardized processes.</p>
                                </div>
                            </div>
                        </div>
                        <button class="cta-button mt-5" onClick={goToStart}>See PropAgile in Action</button>
                    </div>
                </div>
            </section>
            <section className="section-landing" style={{ padding: '150px 0px' }}>
                <div className="container">
                    <h2 className="d-none d-md-block" style={{ fontFamily: "Inter, serif", fontSize: "52px", fontWeight: "700" }}>Save Time, Cut Costs, Reduce Risks</h2>
                    <h2 className="d-block d-md-none" style={{ fontFamily: "Inter, serif", fontSize: "52px", fontWeight: "700", lineHeight: 1.1 }}>Save Time, Cut Costs, Reduce Risks</h2>
                    <div className='d-flex flex-column flex-md-row justify-content-between align-items-center mt-5'>
                        <img src="./images/star.png" alt="" width={350} />
                        <p className="d-none d-md-block" style={{ margin: '0px', padding: '0px 0px 0px 50px', fontSize: "28px" }}>Construction projects are complex, but they don’t have to be chaotic. PropAgile helps you plan smarter, execute with precision, and avoid costly mistakes that derail success.</p>
                        <p className="d-block d-md-none" style={{ margin: '15px 0px', padding: '0px 0px 0px 0px', fontSize: "28px" }}>Construction projects are complex, but they don’t have to be chaotic. PropAgile helps you plan smarter, execute with precision, and avoid costly mistakes that derail success.</p>
                    </div>
                    <div className='landing-card mt-5' >
                        <div className='border card-features-frameworks' style={{ backgroundColor: '#eaeaeaea', padding: "20px 15px" }}>
                            <span className="d-none d-md-block" style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "20px", fontWeight: "600", color: "#1aa354" }}>Save Weeks of Planning: </span>
                            <p className="d-block d-md-none" style={{ textAlign: 'center', fontFamily: "Inter, serif", fontSize: "24px", fontWeight: "600", color: "#1aa354" }}>Save Weeks of Planning </p>
                            <p className='d-none d-md-block mt-2' style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "18px" }}>Transition smoothly from initial ideas to detailed plans. Create workflows quickly, generate ready-to-use checklists, and ensure no detail is overlooked. Avoid wasting weeks—or even months—on missed steps.</p>
                            <p className='d-block d-md-none mt-2' style={{ textAlign: 'center', fontFamily: "Inter, serif", fontSize: "20px" }}>Transition smoothly from initial ideas to detailed plans. Create workflows quickly, generate ready-to-use checklists, and ensure no detail is overlooked. Avoid wasting weeks—or even months—on missed steps.</p>
                        </div>
                        <div className='border card-features-frameworks' style={{ backgroundColor: '#eaeaeaea', padding: "20px 15px" }}>
                            <span className="d-none d-md-block" style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "20px", fontWeight: "600", color: "#1aa354" }}>Eliminate Delays and Conflicts: </span>
                            <p className="d-block d-md-none" style={{ textAlign: 'center', fontFamily: "Inter, serif", fontSize: "23px", fontWeight: "600", color: "#1aa354" }}>Eliminate Delays and Conflicts </p>
                            <p className='d-none d-md-block mt-2' style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "18px" }}>Define scopes, deliverables, and responsibilities clearly to keep your team aligned. Prevent disputes, rework, and delays caused by miscommunication and ambiguity.</p>
                            <p className='d-block d-md-none mt-2' style={{ textAlign: 'center', fontFamily: "Inter, serif", fontSize: "20px" }}>Define scopes, deliverables, and responsibilities clearly to keep your team aligned. Prevent disputes, rework, and delays caused by miscommunication and ambiguity.</p>
                        </div>
                    </div>
                    <div className='landing-card '>
                        <div className='border card-features-frameworks' style={{ backgroundColor: '#eaeaeaea', padding: "20px 15px" }}>
                            <span className="d-none d-md-block" style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "20px", fontWeight: "600", color: "#1aa354" }}>Protect Your Budget: </span>
                            <p className="d-block d-md-none" style={{ textAlign: 'center', fontFamily: "Inter, serif", fontSize: "24px", fontWeight: "600", color: "#1aa354" }}>Protect Your Budget </p>
                            <p className='d-none d-md-block mt-2' style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "18px" }}> Update budgets and timelines as your project progresses to anticipate risks and prevent oversights that could derail finances. Minimize costly mistakes and keep your project on track.</p>
                            <p className='d-block d-md-none mt-2' style={{ textAlign: 'center', fontFamily: "Inter, serif", fontSize: "20px" }}> Update budgets and timelines as your project progresses to anticipate risks and prevent oversights that could derail finances. Minimize costly mistakes and keep your project on track.</p>
                        </div>
                        <div className='border card-features-frameworks' style={{ backgroundColor: '#eaeaeaea', padding: "20px 15px" }}>
                            <span className="d-none d-md-block" style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "20px", fontWeight: "600", color: "#1aa354" }}>Plan with Confidence: </span>
                            <p className="d-block d-md-none" style={{ textAlign: 'center', fontFamily: "Inter, serif", fontSize: "24px", fontWeight: "600", color: "#1aa354" }}>Plan with Confidence </p>
                            <p className='d-none d-md-block mt-2' style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "18px" }}>Set clear budgets, scopes, milestones, and quality benchmarks. Refine timelines, seize opportunities, and secure the resources you need to bring your vision to life.</p>
                            <p className='d-block d-md-none mt-2' style={{ textAlign: 'center', fontFamily: "Inter, serif", fontSize: "20px" }}>Set clear budgets, scopes, milestones, and quality benchmarks. Refine timelines, seize opportunities, and secure the resources you need to bring your vision to life.</p>
                        </div>
                    </div>
                    <p className="mt-5" style={{ textAlign: 'center', fontFamily: "Inter, serif", fontSize: "28px", }}>
                        With PropAgile, you'll avoid the headaches of missteps, delays and miscommunication, allowing you to focus on delivering results.
                    </p>
                    <button class="cta-button" onClick={goToStart}>Save Time, Start Now</button>
                </div>
            </section>
            <section className="section-landing" style={{ padding: '150px 0px', backgroundColor: '#eaeaeaea' }}>
                <div className="container">
                    <h2 className="d-none d-md-block" style={{ fontFamily: "Inter, serif", fontSize: "52px", fontWeight: "700" }}>4 Phases to Streamline Construction <br /><span className='font-green'>from Idea to Completion</span></h2>
                    <h2 className="d-block d-md-none" style={{ fontFamily: "Inter, serif", fontSize: "52px", fontWeight: "700", lineHeight: 1.1 }}>4 Phases to Streamline Construction <br /><span className='font-green'>from Idea to Completion</span></h2>

                    <p className="d-none d-md-block" style={{ textAlign: "center", padding: '0px 0px 0px 0px', fontSize: "28px" }}>PropAgile’s construction frameworks guide you through every phase of the project lifecycle ensuring alignment, efficiency, and readiness at every step. From evaluating feasibility to ensuring operational success, our step-by-step frameworks help you stay in control and deliver exceptional results.</p>
                    <p className="d-block d-md-none" style={{ textAlign: "center", padding: '0px 0px 0px 0px', fontSize: "28px" }}>PropAgile’s construction frameworks guide you through every phase of the project lifecycle ensuring alignment, efficiency, and readiness at every step. From evaluating feasibility to ensuring operational success, our step-by-step frameworks help you stay in control and deliver exceptional results.</p>

                    <div className='landing-card mt-5' >
                        <div className='d-flex flex-row flex-md-column justify-content-center align-items-center border card-features-frameworks' style={{ backgroundColor: 'white', padding: "20px 15px", width: '100%' }}>
                            <img className="d-none d-md-block" src="./images/unionDesktop.png" alt="" width={800} />
                            <img className="d-block d-md-none" src="./images/unionMobile.png" alt="" style={{ height: "1000px" }} />
                            <div className='d-flex flex-column flex-md-row justify-content-around align-items-start mt-0 mt-md-3 ml-3 ml-md-0 card-union' >
                                <div className="d-flex flex-column justify-content-start justify-content-md-between pt-3 pt-md-0" style={{ width: '200px', height: "230px", cursor: 'pointer' }} onClick={() => { handleShowPhaseModal(1) }}>
                                    <div>
                                        <span style={{ textAlign: 'center', fontFamily: "Inter, serif", fontSize: "16px", fontWeight: "600", color: "#1aa354" }}>Feasibility Phase (F)</span>
                                        <p style={{ margin: '5px 0px', padding: '0px 5px', fontSize: "14px" }}>Evaluate project viability by aligning financial goals, regulatory requirements, and strategic objectives to ensure readiness for execution.</p>
                                    </div>
                                    <p style={{ margin: '5px 0px', padding: '0px 5px', fontSize: "14px", color: "#ff7200", cursor: 'pointer' }} >Learn more...</p>

                                </div>
                                <div className="d-flex flex-column justify-content-start justify-content-md-between " style={{ width: '200px', height: "230px", cursor: 'pointer' }} onClick={() => { handleShowPhaseModal(2) }}>
                                    <div>
                                        <span style={{ textAlign: 'center', fontFamily: "Inter, serif", fontSize: "16px", fontWeight: "600", color: "#1aa354" }}>Planning Phase (P)</span>
                                        <p style={{ margin: '5px 0px', padding: '0px 5px', fontSize: "14px" }}>Develop a comprehensive strategy for design, procurement, budgeting, scheduling, and construction preparation to ensure project alignment and readiness.</p>
                                    </div>
                                    <p style={{ margin: '5px 0px', padding: '0px 5px', fontSize: "14px", color: "#ff7200", cursor: 'pointer' }} >Learn more...</p>
                                </div>
                                <div className="d-flex flex-column justify-content-start justify-content-md-between " style={{ width: '200px', height: "230px", cursor: 'pointer' }} onClick={() => { handleShowPhaseModal(3) }}>
                                    <div>
                                        <span style={{ textAlign: 'center', fontFamily: "Inter, serif", fontSize: "16px", fontWeight: "600", color: "#1aa354" }}>Construction Phase (C)</span>
                                        <p style={{ margin: '5px 0px', padding: '0px 5px', fontSize: "14px" }}>Execute the physical building process with precision, ensuring quality, efficiency, and adherence to plans, specifications, quality, timelines and budget.</p>
                                    </div>
                                    <p style={{ margin: '5px 0px', padding: '0px 5px', fontSize: "14px", color: "#ff7200", cursor: 'pointer' }} >Learn more...</p>
                                </div>
                                <div className="d-flex flex-column justify-content-start justify-content-md-between " style={{ width: '200px', height: "230px", cursor: 'pointer' }} onClick={() => { handleShowPhaseModal(4) }}>
                                    <div>
                                        <span style={{ textAlign: 'center', fontFamily: "Inter, serif", fontSize: "16px", fontWeight: "600", color: "#1aa354" }}>Handover Phase (H)</span>
                                        <p style={{ margin: '5px 0px', padding: '0px 5px', fontSize: "14px" }}>Transfer the completed project to the owner or operator with full operational readiness, training, and strategies for long-term success.</p>
                                    </div>
                                    <p style={{ margin: '5px 0px', padding: '0px 5px', fontSize: "14px", color: "#ff7200", cursor: 'pointer' }} >Learn more...</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <section className="section-landing" style={{ padding: '150px 0px' }}>
                <div className="container">
                    <h2 className="d-none d-md-block" style={{ fontFamily: "Inter, serif", fontSize: "52px", fontWeight: "700" }}>Built for today. Evolving for tomorrow</h2>
                    <h2 className="d-block d-md-none" style={{ fontFamily: "Inter, serif", fontSize: "52px", fontWeight: "700", lineHeight: 1.1 }}>Built for today. Evolving for tomorrow</h2>
                    <p style={{ fontFamily: "Inter, serif", fontSize: "28px" }}>
                        <b>PropAgile</b> is designed to meet your current needs <br /> and grow with you as the industry evolves.

                    </p>
                    <p style={{ fontFamily: "Inter, serif", fontSize: "28px" }}>
                        Explore what's here now and what's coming next.
                    </p>

                    <div class="container-timeline mt-5">
                        <div class="single-box box-right">
                            <div class="custom"></div>
                            <div class="date-area">
                                <span className="">Q4<br />2024</span>
                            </div>
                            <div className="content-time">
                                <h2 style={{ textAlign: 'center', fontFamily: "Inter, serif", fontSize: "24px", fontWeight: "600", margin: '0px', color: "#1aa354" }}>Phase 1: Launch</h2>
                                <ul className='timeline-ul'>
                                    <li style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "18px", margin: '0px 18px' }}>Pre-development and preconstruction frameworks</li>
                                    <li style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "18px", margin: '18px 18px' }}>Manage up to 3 projects</li>
                                    <li style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "18px", margin: '18px 18px' }}>Interactive table of contents</li>
                                </ul>
                            </div>
                        </div>
                        <div class="single-box">
                            <div class="custom"></div>
                            <div class="date-area">
                                <span className="">Q1-Q4<br />2025</span>
                            </div>
                            <div className="content-time">

                                <h2 style={{ textAlign: 'center', fontFamily: "Inter, serif", fontSize: "24px", fontWeight: "600", margin: '0px', color: "#1aa354" }}>Phase 2: Collaboration</h2>
                                <ul className='timeline-ul'>
                                    <li style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "18px", margin: '0px 18px' }}>Construction and post-construction frameworks.</li>
                                    <li style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "18px", margin: '18px 18px' }}>Align design teams with milestones and deliverables.</li>
                                    <li style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "18px", margin: '18px 18px' }}>Create project parameters and Work Breakdown Structures (WBS).</li>
                                    <li style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "18px", margin: '18px 18px' }}>Enable work packaging, smart grouping, and filtering.</li>
                                    <li style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "18px", margin: '18px 18px' }}>Add documentation, version control, and guest access.</li>
                                    <li style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "18px", margin: '18px 18px' }}>Leverage reuse and cloud storage for seamless collaboration.</li>
                                </ul>
                            </div>
                        </div>
                        <div class="single-box box-right">
                            <div class="custom"></div>
                            <div class="date-area">
                                <span className="">Q1-Q4<br />2026</span>
                            </div>
                            <div className="content-time">
                                <h2 style={{ textAlign: 'center', fontFamily: "Inter, serif", fontSize: "24px", fontWeight: "600", margin: '0px', color: "#1aa354" }}>Phase 3: Precision</h2>
                                <ul className='timeline-ul'>
                                    <li style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "18px", margin: '0px 18px' }}>Develop detailed scopes of work and specification writing.</li>
                                    <li style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "18px", margin: '18px 18px' }}>Streamline procurement and manage RFIs, submittals, and shop drawing reviews.</li>
                                    <li style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "18px", margin: '18px 18px' }}>Integrate with third-party platforms to enhance project workflows.</li>
                                </ul>
                            </div>
                        </div>
                        <div class="single-box">
                            <div class="custom"></div>
                            <div class="date-area">
                                <span className="">Q1-Q4<br />2027</span>
                            </div>
                            <div className="content-time">
                                <h2 style={{ textAlign: 'center', fontFamily: "Inter, serif", fontSize: "24px", fontWeight: "600", margin: '0px', color: "#1aa354" }}>Phase 4: Innovation</h2>
                                <ul className='timeline-ul'>
                                    <li style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "18px", margin: '0px 18px' }}>Predictive workflows for smarter planning.</li>
                                    <li style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "18px", margin: '18px 18px' }}>Real-time analytics dashboards for actionable insights.</li>
                                    <li style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "18px", margin: '18px 18px' }}>Stakeholder engagement hub to keep everyone aligned.</li>
                                    <li style={{ textAlign: 'start', fontFamily: "Inter, serif", fontSize: "18px", margin: '18px 18px' }}>Sustainability metrics to prioritize environmentally conscious decisions.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-landing" style={{ padding: '150px 0px', backgroundColor: '#eaeaeaea' }}>
                <div className="container">
                    <h2 className="d-none d-md-block" style={{ textAlign: 'center', fontFamily: "Inter, serif", fontSize: "52px", fontWeight: "600", color: "#1aa354" }}>Why Choose PropAgile?</h2>
                    <h2 className="d-block d-md-none" style={{ textAlign: 'center', fontFamily: "Inter, serif", fontSize: "52px", fontWeight: "600", color: "#1aa354", lineHeight: 1.1 }}>Why Choose PropAgile?</h2>
                    <p style={{ textAlign: 'center', fontFamily: "Inter, serif", fontSize: "28px" }}>The construction process doesn’t need to feel overwhelming. PropAgile gives you the tools, structure, and clarity to lead your projects with confidence.</p>
                    <div className='row justify-content-between '>
                        <div className='col-12 col-md-4 ' >
                            <div className="card-features" style={{ backgroundColor: 'white', height: "290px" }}>
                                <span className="d-none d-md-block" style={{ textAlign: 'center', fontFamily: "Inter, serif", fontSize: "20px", fontWeight: "600", color: "#1aa354" }}>For Owners</span>
                                <span className="d-block d-md-none" style={{ textAlign: 'center', fontFamily: "Inter, serif", fontSize: "24px", fontWeight: "600", color: "#1aa354" }}>For Owners</span>
                                <p className="d-none d-md-block mt-2" style={{ textAlign: 'center', fontFamily: "Inter, serif", fontSize: "18px" }}>Keep your vision on track with tools that ensure clear deliverables, real-time updates, and aligned milestones</p>
                                <p className="d-block d-md-none mt-2" style={{ textAlign: 'center', fontFamily: "Inter, serif", fontSize: "20px" }}>Keep your vision on track with tools that ensure clear deliverables, real-time updates, and aligned milestones</p>
                            </div>
                        </div>
                        <div className='col-12 col-md-4' >
                            <div className="card-features" style={{ backgroundColor: 'white', height: "290px" }}>
                                <span className="d-none d-md-block" style={{ textAlign: 'center', fontFamily: "Inter, serif", fontSize: "20px", fontWeight: "600", color: "#1aa354" }}>For Builders and Contractors</span>
                                <span className="d-block d-md-none" style={{ textAlign: 'center', fontFamily: "Inter, serif", fontSize: "24px", fontWeight: "600", color: "#1aa354" }}>For Builders and Contractors</span>
                                <p className="d-none d-md-block mt-2" style={{ textAlign: 'center', fontFamily: "Inter, serif", fontSize: "18px" }}>Simplify workflow management, eliminate confusion, improve communication, and execute efficiently with standardized processes and operations.</p>
                                <p className="d-block d-md-none mt-2" style={{ textAlign: 'center', fontFamily: "Inter, serif", fontSize: "20px" }}>Simplify workflow management, eliminate confusion, improve communication, and execute efficiently with standardized processes and operations.</p>
                            </div>
                        </div>
                        <div className='col-12 col-md-4 '>
                            <div className="card-features" style={{ backgroundColor: 'white', height: "290px" }}>
                                <span className="d-none d-md-block" style={{ textAlign: 'center', fontFamily: "Inter, serif", fontSize: "20px", fontWeight: "600", color: "#1aa354" }}>For Design Teams</span>
                                <span className="d-block d-md-none" style={{ textAlign: 'center', fontFamily: "Inter, serif", fontSize: "24px", fontWeight: "600", color: "#1aa354" }}>For Design Teams</span>
                                <p className="d-none d-md-block mt-2" style={{ textAlign: 'center', fontFamily: "Inter, serif", fontSize: "18px" }}>Empower project managers with frameworks that ensure control over every deliverable and critical detail. Enhance collaboration, streamline processes, and boost efficiency across the board.</p>
                                <p className="d-block d-md-none mt-2" style={{ textAlign: 'center', fontFamily: "Inter, serif", fontSize: "20px" }}>Empower project managers with frameworks that ensure control over every deliverable and critical detail. Enhance collaboration, streamline processes, and boost efficiency across the board.</p>
                            </div>
                        </div>
                    </div>
                    <p className="mt-5" style={{ textAlign: 'center', fontFamily: "Inter, serif", fontSize: "28px" }}>
                        Whether you’re managing one project or several, PropAgile adapts to your workflow, helping you deliver better results, faster.
                    </p>
                    <button class="cta-button" onClick={goToStart}>Tailor Your Framework</button>
                </div>
            </section>
            <section className="section-landing" style={{ backgroundColor: '#168ea3', padding: '150px 0px' }}>
                <div className="container">
                    <p style={{ textAlign: 'center', fontFamily: "Inter, serif", fontSize: "28px", color: "white" }}>PropAgile gives you the tools and structure to simplify processes, align your teams, and deliver results faster. Start your project now and lead with confidence.</p>
                    <h2 className="d-none d-md-block" style={{ textAlign: 'center', fontFamily: "Inter, serif", fontSize: "75px", fontWeight: "600", color: "white", margin: '80px 0px', lineHeight: 1.2 }}>Ready to take control <br /> of your next project?</h2>
                    <h2 className="d-block d-md-none" style={{ textAlign: 'center', fontFamily: "Inter, serif", fontSize: "65px", fontWeight: "600", color: "white", margin: '80px 0px', lineHeight: 1.2 }}>Ready to take control of your next project?</h2>
                    <button class="cta-button" onClick={goToStart}>Get Started Today</button>
                </div>
            </section>
            

                <Footer />
                {/* <div className="social-icons">
                    <a href="https://www.facebook.com/PropAgile/" target='_blank'><i className="fab fa-facebook"></i></a>
                    <a href="https://www.linkedin.com/company/propagile" target='_blank'><i className="fab fa-linkedin"></i></a>
                    <a href="https://www.instagram.com/propagile/" target='_blank'><i className="fab fa-instagram"></i></a>
                    <a href="https://www.youtube.com/@ConstructionConnections" target='_blank'><i className="fab fa-youtube"></i></a>
                </div>
                <div className="footer-links">
                    <a href={CLIENT_PROPAGILE_URL}>2024 PropAgile</a>
                    /
                    <a href={CLIENT_PROPAGILE_URL + "/terms"}> Terms of Use</a>
                    /
                    <a href={CLIENT_PROPAGILE_URL + "/privacy-policy"}> Privacy Policy</a>
                </div> */}
           
        </div>
    )
}

export default LandingPage