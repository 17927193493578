import { BASE_URL } from "../Constants/serverConfig";
import axios from 'axios';

// Configuración base de Axios
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Función para agregar el encabezado Authorization
const addAuthorizationHeader = (token) => {
  if (token) {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axiosInstance.defaults.headers.common['Authorization'];
  }
};

// Manejo de errores centralizado
const handleApiError = (error) => {
  if (error.response) {
    // El servidor respondió con un código de estado fuera del rango 2xx
    throw new Error(`Error ${error.response.status}: ${error.response.data.message || 'Ocurrió un error'}`);
  } else if (error.request) {
    // La solicitud fue hecha, pero no hubo respuesta
    throw new Error('Error: No se recibió respuesta del servidor');
  } else {
    // Algo sucedió al configurar la solicitud
    throw new Error(`Error: ${error.message}`);
  }
};

// Función para obtener proyectos
export const fetchProjects = async (token = null) => {
  try {
    addAuthorizationHeader(token); // Agregar encabezado opcional
    const response = await axiosInstance.get('/project');    
    return response.data.data.projects;
  } catch (error) {
    handleApiError(error);
  }
};

// Función para crear un proyecto
export const createProject = async (projectData, token = null) => {
  try {
    addAuthorizationHeader(token); // Agregar encabezado opcional
    const response = await axiosInstance.post('/project', projectData);
    return response.data.data.project;
  } catch (error) {
    handleApiError(error);
  }
};

// Función para actualizar un proyecto
export const updateProject = async (projectId, projectData, token = null) => {
  try {
    addAuthorizationHeader(token); // Agregar encabezado opcional
    const response = await axiosInstance.patch(`/project/${projectId}`, projectData);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Función para eliminar un proyecto
export const deleteProject = async (projectId, projectData, token = null) => {
  try {    
    addAuthorizationHeader(token); // Agregar encabezado opcional
    const response = await axiosInstance.delete(`/project/${projectId}`, projectData);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};
