import { useEffect, useState } from 'react';
import { fetchProjects, createProject, updateProject, deleteProject } from '../api/projects';

const useProjects = (token) => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Obtener todos los proyectos
  const getProjects = async () => {

    if (!token) return;

    setLoading(true);
    setError(null);
    try {
      const data = await fetchProjects(token);
      setProjects(data);
    } catch (err) {
      setProjects([]);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Crear un proyecto
  const addProject = async (projectData) => {
    setLoading(true);
    setError(null);
    try {
      const newProject = await createProject({ project_data: projectData }, token);
      setProjects((prevProjects) => [...prevProjects, newProject]);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Actualizar un proyecto
  const editProject = async (projectId, updatedData) => {
    setLoading(true);
    setError(null);
    try {
      const updatedProject = await updateProject(projectId, updatedData, token);
      setProjects((prevProjects) =>
        prevProjects.map((project) =>
          project.id === projectId ? updatedData.project_data : project
        )
      );
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Eliminar un proyecto
  const removeProject = async (projectId, projectData) => {
    setLoading(true);
    setError(null);
    try {
      let project = await deleteProject(projectId, projectData, token);
      setProjects((prevProjects) =>
        prevProjects.filter((project) => project.id !== projectId)
      );
      return project;
    } catch (err) {
      setError(err.message);
      return {
        tatus: 'Failed',
        message: 'Authentication error, please try again.'
      }      
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProjects();
  }, [token]); // Llama a getProjects cada vez que cambia el token

  return {
    projects,
    loading,
    error,
    getProjects,
    addProject,
    editProject,
    removeProject,
  };
};

export default useProjects;
