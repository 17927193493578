import React, { useState, useEffect } from 'react'
import IndividualInformation from '../ServiceProviders/components/individualInformation'
import NotificationLayout from './NotificationLayout';

function AccountLayout({ token, serviceProvider, panel, changeMenu }) {

    const [typePanel, setTypePanel] = useState("");

    useEffect(() => {
        setTypePanel(panel);
    }, [panel]);  


    const renderPageContent = (panel) => {

        switch (panel) {

            case 'Notifications':
                return (<NotificationLayout />)
            default:
                return (
                    <IndividualInformation
                        token={token}
                        serviceProvider={serviceProvider}
                        changeTypePanel={changeMenu}
                    />
                );

        }
    }
    return (
        <div>
            {renderPageContent(typePanel)}
        </div>
    )
}

export default AccountLayout