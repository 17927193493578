import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import {loadStripe} from '@stripe/stripe-js';
import { Elements } from "@stripe/react-stripe-js";
import { createStore } from "redux";
import { Provider } from "react-redux";
import "./index.css";
import App from "./Components/App.jsx";
import Reducers from "./Reducers/index.jsx";
import * as serviceWorker from "./serviceWorker";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import "bootstrap/dist/js/bootstrap.bundle.min";
// import { ToastProvider } from "react-toast-notifications";

const store = createStore(Reducers);
const stripePromise = loadStripe('pk_live_D6acKRm2oyG2nOn7DGnJTlv300d5qmN16t');
ReactDOM.render(
  
    <Provider store={store}>
      <BrowserRouter>
        {/* <ToastProvider> */}
          <App />
        {/* </ToastProvider> */}
      </BrowserRouter>
    </Provider>
  ,
  document.getElementById("root")
);

serviceWorker.unregister();
