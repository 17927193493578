import React from 'react';
import NavLink from '../Atoms/NavLink';

const NavMenu = ({ items, selectedPage, isMobile }) => (
  <ul className="d-flex flex-row navbar-nav ms-auto mb-2 mb-lg-0">
    {items.map((item, index) => (
      <div style={{borderLeft: (index == 0 && isMobile) ? '0px' :  '1px solid gray', borderRight: (index == 1 && isMobile) ? '0px' : '1px solid gray' }}>
        <NavLink
          key={index}
          label={item.label}
          href={item.href}
          onClick={item.onClick}
          active={selectedPage === item.label} />
      </div>
    ))}

  </ul>
);

export default NavMenu;
