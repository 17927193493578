import { useEffect, useState } from 'react';
import { fetchSubscription } from '../api/subscriptions';

const useSubscriptions = (token) => {
  const [subscription, setSubscription] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Obtener todos los proyectos
  const getSubscription = async () => {

    if (!token) return;

    setLoading(true);
    setError(null);
    try {
      const data = await fetchSubscription(token);
      setSubscription(data);
    } catch (err) {
      setSubscription([]);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSubscription();
  }, [token]); // Llama a getProjects cada vez que cambia el token

  return {
    subscription,
    loading,
    error,
    getSubscription
  };
};

export default useSubscriptions;
