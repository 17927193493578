import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import '../../style.css'
const CollapsibleList = forwardRef(({ items, level = 0, prefix = '', padding, show = 'block', currentNote, setCurrentNote, setCurrentNoteName, saveNote, getNote, setNoteMobile }, ref) => {
  const treeRef = useRef(null);
  const [selectedItems, setSelectedItems] = useState([]);

  const saveCheckedItems = () => {
    const checkedCheckboxes = treeRef.current.querySelectorAll('input[type="checkbox"]:checked');
    const selected = Array.from(checkedCheckboxes).map((checkbox) => ({
      id: checkbox.id,
      parent_id: checkbox.getAttribute('data-parent-id'),
    }));
    setSelectedItems(selected);
    return selected; // Retorna los elementos seleccionados
  };

  // Exponer el método saveCheckedItems a través de la referencia
  useImperativeHandle(ref, () => ({
    saveCheckedItems
  }));

  return (

    <ol ref={treeRef} className="container-framework-list" style={{ paddingLeft: padding + "px", display: (show), width: 'max-content' }}>
      {items && items.map((item, index) => {
        const currentNumber = prefix ? `${prefix}.${index + 1}` : `${index + 1}`;
        return <CollapsibleItem key={item.id} item={item} level={level} currentNumber={currentNumber} getSelectedItems={saveCheckedItems} currentNote={currentNote} setCurrentNote={setCurrentNote} setCurrentNoteName={setCurrentNoteName} saveNote={saveNote} getNote={getNote} setNoteMobile={setNoteMobile} />;
      })}
    </ol>

  );
});

const CollapsibleItem = ({ item, level, currentNumber, getSelectedItems, currentNote, setCurrentNote, setCurrentNoteName, saveNote, getNote, setNoteMobile }) => {

  // Check if the item or any child is checked to determine if it should be open
  const hasCheckedChildren = (item) => {
    if (item.checked) return true;
    return item.children?.some(hasCheckedChildren);
  };

  const calculateIndeterminateState = (item) => {
    const childChecked = item.children?.some((child) => child.checked);
    const childUnchecked = item.children?.some((child) => !child.checked && !child.indeterminate);

    return childChecked && childUnchecked; // Indeterminado si hay hijos marcados y no marcados
  };

  const [isOpen, setIsOpen] = useState(() => hasCheckedChildren(item)); // true if all open
  const [isChecked, setIsChecked] = useState(item.checked || false);
 // const [isIndeterminate, setIsIndeterminate] = useState(() => calculateIndeterminateState(item));
  const [isIndeterminate, setIsIndeterminate] = useState(() => calculateIndeterminateState(item));
  const [showSaveTextArea, setShowSaveTextArea] = useState(false);
  const [note, setNote] = useState(item.note);

  
  useEffect(() => {
    setIsOpen(hasCheckedChildren(item)); // Sync isOpen when item.checked changes
    setIsChecked(item.checked || false);
    //setIsIndeterminate(calculateIndeterminateState(item));
  }, [item]);



  const toggle = (e) => {
    e.stopPropagation(); // Evitar conflictos con otros eventos   
    setIsOpen(!isOpen)
  };

  const handleCheckItem = (e, item) => {
    e.stopPropagation();
    setIsChecked(e.target.checked);

    if (e.target.checked) {
      setIsOpen(true);
    }
    handleChangeItem(e.target);
  }

  const handleChangeItem = (checkbox) => {
    
    
    const isChecked = checkbox.checked;
    const parentNode = checkbox.closest("li");


    // Marcar o desmarcar hijos
    const childCheckboxes = parentNode.querySelectorAll("ol input[type='checkbox']");

    /** 
     * Deshabilitado pero pendiente de activacion
    */

    // childCheckboxes.forEach((child) => {
    //   child.checked = isChecked; // Sincronizar estado
    //   child.indeterminate = false;
    // });

    // Propagar hacia arriba: sincronizar el estado de los padres
   // updateParentState(parentNode);

  }

  const updateParentState = (node) => {
    let currentParent = node.parentElement.closest("li");
    while (currentParent) {
      const parentCheckbox = currentParent.querySelector(":scope > input[type='checkbox']");
      const childCheckboxes = currentParent.querySelectorAll(":scope >  ol input[type='checkbox']");

      const allChecked = [...childCheckboxes].every((child) => child.checked);
      const someChecked = [...childCheckboxes].some((child) => child.checked);

      
      if (parentCheckbox) {

        parentCheckbox.checked = allChecked;
        // parentCheckbox.indeterminate = !allChecked && someChecked; // Estado intermedio
        // if (parentCheckbox.indeterminate) {
        //   parentCheckbox.classList.add("indeterminate");
        // } else {
        //   parentCheckbox.classList.remove("indeterminate");
        // }

      }

      currentParent = currentParent.parentElement.closest("li");
    }
  };

  const handleIShowTextAreaMobile = async (e, id) => {
    e.stopPropagation();

    if (item.id === currentNote) {
      setCurrentNote("");
    } else {
      setCurrentNote(id);
      setCurrentNoteName(item.name);
      let note = await getNote(item.id);
      setNoteMobile(note);
      setNote(note);
    }
  };

  const handleIShowTextArea = async (e, id) => {
    e.stopPropagation();    
    if (item.id === currentNote) {
      setCurrentNote("");
    } else {
      setCurrentNote(id);
      setCurrentNoteName(item.name);
      let note = await getNote(item.id);
      //getNote(item.id);
      setNoteMobile(note);
      setNote(note);
    }
  };

  const handleTextArea = (e) => {
    setShowSaveTextArea(true);
    setNote(e.target.value)
  }

  const SaveTextArea = (e) => {
    e.stopPropagation();
    saveNote(item.id, note);
  }


  return (
    <li style={{ marginLeft: `${0}px`, marginRight: '15px' }} key={item.id} onClick={toggle}>

      {item.children.length > 0 &&
        < small className='icon-open ' style={{ color: '#8d8d8d', fontFamily: "Inter, serif" }}>
          {isOpen > 0 ?
            <i class="bi bi-caret-down-fill"></i> :
            <i class="bi bi-caret-right-fill"></i>
          }
          &nbsp;
        </small>
      }
      <input
        ref={(input) => input && (input.indeterminate = false)} // Actualizar indeterminado dinámicamente
        type="checkbox"
        id={item.id}
        className={`checkbox-frameworks ${false ? 'indeterminate' : ''}`}
        name="frameworks"
        data-id={item.id}
        data-parent-id={item.parent_id}
        onClick={(e) => { handleCheckItem(e, item) }}
        checked={isChecked} />
      &nbsp;
      <span style={{ color: (isChecked ? '#1aa354' : '#3f3f3f'), fontFamily: "Inter, serif", fontSize: "14px" }}>{item.id}</span> <span style={{ color: (isChecked ? '#1aa354' : 'gray'), fontFamily: "Inter, serif", fontSize: "14px" }}>{item.name}</span>
      &nbsp;
      {isChecked &&
        <>          
          <i className="bi bi-file-text-fill btn-mobile-notes" style={{ color: "#666666", cursor: 'pointer' }} onClick={(e) => { handleIShowTextAreaMobile(e, item.id) }}></i>
          <i className="m-0 p-0 bi-file-text-fill btn-desktop-notes" style={{ color: "#666666", cursor: 'pointer' }} onClick={(e) => { handleIShowTextArea(e, item.id) }}></i>
        </>
      }
      {/* {currentNote === item.id &&
        <div className="div-md-textarea" style={{ position: 'relative' }}>

          <textarea className="d-none d-md-block" placeholder="Write a note or requirement" style={{ display: 'block', marginTop: '10px', borderColor: '#d0d0d0', padding: '0px 5px', width: '100%' }} value={note} onClick={(e) => e.stopPropagation()} onChange={handleTextArea} />
          {showSaveTextArea && <span className='button-textarea'
            style={{
              position: "absolute",
              bottom: "5px",
              right: "20px",
              padding: "0px 8px",
              backgroundColor: "#1a67a3",
              borderRadius: "5px",
              letterSpacing: "2px",
              color: "white",
              fontFamily: "'Inter', serif",
              fontSize: "12px"
            }}
            onClick={SaveTextArea}
          >Save</span>}
        </div>
      } */}
      {
        item.children.length > 0 && (
          <CollapsibleList items={item.children} level={level + 1} prefix={currentNumber} padding={20} show={isOpen ? 'block' : 'none'} currentNote={currentNote} setCurrentNote={setCurrentNote} setCurrentNoteName={setCurrentNoteName} saveNote={saveNote} getNote={getNote} setNoteMobile={setNoteMobile} />
        )
      }
    </li >
  );
};

export default CollapsibleList;
