import React, { useState, useRef, useEffect } from 'react';
import '../style.css'
import { OverlayTrigger, Popover } from 'react-bootstrap';
const FrameworkCard = ({ id, name, description, total_items, checked, selectedTab, selectFramework, removeFramework, updateFramework, showTableContent, downloadFramework, stopTour }) => {

    const [showPopover, setShowPopover] = useState(false);
    const [placement, setPlacement] = useState('right');

    const target = useRef(null);
    const isCustomFramework = (selectedTab === 'custom');

    useEffect(() => {
        if (showPopover) {
            stopTour && stopTour();
        }
    }, [showPopover])

    useEffect(() => {
        const updatePlacement = () => {
            if (window.innerWidth <= 768) {
                setPlacement('bottom'); // Mobile
            } else {
                setPlacement('bottom'); // Desktop
            }
        };

        // Set placement on initial render
        updatePlacement();

        // Update placement on window resize
        window.addEventListener('resize', updatePlacement);
        return () => window.removeEventListener('resize', updatePlacement);
    }, []);

    const renderTooltip = (
        <Popover id="popover-basic" onMouseEnter={() => setShowPopover(true)} onMouseLeave={() => setShowPopover(false)}>

            <Popover.Content>
                {total_items > 0 ?
                    <ul className='ml-1 framework-ul' >
                        <li className='popover-li' style={{ fontFamily: 'Inter, serif', color: '#1a67a3', cursor: 'pointer' }} onClick={() => { showTableContent(id) }}>See Interactive Table of Content</li>
                        <li className='popover-li' style={{ fontFamily: 'Inter, serif', color: '#1a67a3', cursor: 'pointer' }} onClick={() => { downloadFramework(id,name) }}>Download Custom Framework</li>
                    </ul>
                    :
                    <p style={{ fontFamily: 'Inter, serif', color: '#1a67a3' }}>
                       No items selected yet.
                    </p>
                }
            </Popover.Content>
        </Popover>
    );

    return (
        <div className="card tour-download-framework" style={{ width: '100%', height: '100%', cursor: 'pointer' }}>
            <div className="d-flex flex-column card-body p-0" style={{ borderLeft: (isCustomFramework ? '0px solid #fcc75c' : '0px solid #1a67a3'), borderRadius: '3px', border: '1px solid ' + (checked ? '#1aa35480' : '#e7e7e7') }}>
                <div className='' style={{ backgroundColor: checked ? '#1aa35480' : '#e7e7e7', padding: '5px 15px', height: '8vh' }}>
                    <h5 className="card-title m-0" style={{ fontSize: '14px', textAlign: 'left', fontFamily: 'Inter, serif', color: checked ? '#4c4c4c' : 'unset', letterSpacing: '.3px' }}>{name}</h5>
                </div>
                <div className='d-flex flex-column justify-content-between' style={{ height: '100%' }}>
                    <p className="card-text m-0" style={{ textAlign: 'left', padding: '5px 15px', fontFamily: 'Inter, serif', fontSize: '13px' }}>
                        {/* {description.substring(0, 80)} {description.length >= 80 && '...'} */}
                        {(total_items !== 0 && checked) && ` ${total_items} ${(total_items > 1 ? ' items': ' item')} selected.`}
                        {(total_items == 0 && checked) && `No items selected yet.`}
                    </p>
                    <div className='row justify-content-center mx-2'>

                        {checked &&
                            <>
                                <div className={'col-5 text-center my-1 btn-edit-framework tour-edit-framework-' + (checked ? "1" : "")} style={{ borderRight: '1px solid #e7e7e7', backgroundColor: '#f9f9f9', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' }} onClick={() => { updateFramework(id) }}>
                                    <i className="px-2" style={{ fontStyle: 'normal', fontFamily: 'Inter, serif', margin: '0px', padding: '0px', fontSize: '12px', fontWeight: '600', color: '#626262' }}>EDIT</i>
                                </div>
                                <div className='col-5 text-center my-1 btn-remove-framework' style={{ borderRight: '1px solid #e7e7e7', backgroundColor: '#f9f9f9' }} onClick={() => { removeFramework(id) }}>
                                    <i className="" style={{ fontStyle: 'normal', fontFamily: 'Inter, serif', margin: '0px', padding: '0px', fontSize: '12px', fontWeight: '600', color: '#626262' }}>REMOVE</i>
                                </div>
                                <OverlayTrigger

                                    placement={placement}
                                    overlay={renderTooltip}
                                    delay={{ show: 250, hide: 400 }}
                                    show={showPopover}
                                >
                                    <div className='col-2 text-center my-1 btn-more-framework ' style={{ backgroundColor: '#f9f9f9', borderTopRightRadius: '5px', borderBottomRightRadius: '5px' }} onMouseEnter={() => setShowPopover(true)} onMouseLeave={() => setShowPopover(false)}>
                                        <i className="" style={{ fontStyle: 'normal', fontFamily: 'Inter, serif', margin: '0px', padding: '0px', fontSize: '12px', fontWeight: '600', color: '#626262' }}>...</i>


                                    </div>
                                </OverlayTrigger>
                            </>
                        }

                        {!checked &&
                            <>
                                <div className='col-12 text-center my-1 btn-add-framework' style={{ backgroundColor: '#f9f9f9', borderRadius: '5px' }} onClick={() => { selectFramework(id) }}>
                                    <i className="" style={{ fontStyle: 'normal', fontFamily: 'Inter, serif', margin: '0px', padding: '0px', fontSize: '12px', fontWeight: '600', color: '#626262' }}>ADD</i>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div >
    );
};

export default FrameworkCard;
