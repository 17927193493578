import React from 'react'
import useProjects from '../../hooks/useProjects';
import ProjectCard from '../Atoms/ProjectCard';
function DashboardLayout({ token, handleSelectedRecentProject }) {

  const { projects } = useProjects(token);

  return (
    <div>
      <div>
        <h5>Recent Projects</h5>
        <div className='d-flex flex-column flex-md-row p-3 recent-panel'>
          <div className='recent-project-card container-project mt-3 w-100'>
            {projects.length > 0 ? (
              projects.map((project, index) => (
                <div key={index} className="container-project-item" onClick={()=>{handleSelectedRecentProject(project)}}>
                  <ProjectCard
                    {...project}
                    editable={false}
                  />
                </div>
              ))
            ) : (
              <p>You don't have any projects created yet. Start a new one</p>
            )}
          </div>
        </div>
      </div>
      <div className='mt-5'>
        <h5>Discover</h5>
        <a href='https://www.youtube.com/@ConstructionConnections' target='_blank'>
          <div className='d-flex flex-column flex-md-row p-3 discover-panel'>
            <div><h3 className='discover-title text-dark'>Get Starter with 'Quick start' video tutorials</h3></div>
            <div className='discover-image'><img src={'https://d1ih8jugeo2m5m.cloudfront.net/2024/12/cuanto_paga_youtube.webp'} width={300}></img></div>
          </div>
        </a>
      </div>
    </div >
  )
}

export default DashboardLayout
