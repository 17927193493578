import React, { Component } from "react";
import axios from "axios";
import querystring from "querystring";
import { BASE_URL } from "../../../Constants/serverConfig";
import Loader from "../../Loader/loader";
import { Paper } from "@mui/material";
import styles from "./CompanyInfo.module.css";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      showLoader: false,
    };
  }

  startLoader = () => {
    this.setState({
      showLoader: true,
    });
  };

  stopLoader = () => {
    this.setState({
      showLoader: false,
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  changePassword = (event) => {
    event.preventDefault();
    const { currentPassword, newPassword, confirmNewPassword } = this.state;
    const { showError, showSuccess, token, clearMessage, closeModal } = this.props;
    if (newPassword !== confirmNewPassword) {
      showError("New password and confirm password does not match.");
      return;
    }
    this.startLoader();
    axios
      .patch(
        `${BASE_URL}/account/password`,
        {
          current_password: currentPassword,
          new_password: newPassword,
        },
        {
          headers: {
            // authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        this.stopLoader();
        if (response.status === 200) {
          showSuccess("Password changed successfully");
          setTimeout(clearMessage, 5000);
          this.setState({
            currentPassword: "",
            newPassword: "",
            confirmNewPassword: "",          
          });
          closeModal();
        }
      })
      .catch((error) => {
        this.stopLoader();
        if (error.response.status === 401) {
          showError("Invalid old password.");
        }
      });
  };

  render() {
    const {
      currentPassword,
      newPassword,
      confirmNewPassword,
      showLoader,
    } = this.state;
    return (
      <div>
        <Loader text="Loading..." open={showLoader} />
        <div  className={styles.profileInformationPanel}>
          <form onSubmit={this.changePassword} style={{width:"100%"}}>
            <div className={styles.itemsPasswordPanel} style={{padding:'10px'}}>
              <div className="form-group  mt-4">
                
                <input
                  type="password"
                  value={currentPassword}
                  name="currentPassword"
                  onChange={this.handleChange}
                  required
                  autoFocus
                  className={"form-control " + styles.inputPasswordPanel}
                  placeholder=""
                />
                <label className={styles.passwordLabelInput}>Current Password</label>
              </div>
              <div className="form-group mt-4">
                
                <input
                  type="password"
                  value={newPassword}
                  name="newPassword"
                  onChange={this.handleChange}
                  required
                  className={"form-control " + styles.inputPasswordPanel}
                  placeholder=""
                />
                <label className={styles.passwordLabelInput}>New Password</label>
              </div>
              <div className="form-group mt-4">
               
                <input
                  type="password"
                  value={confirmNewPassword}
                  name="confirmNewPassword"
                  onChange={this.handleChange}
                  required
                  className={"form-control " + styles.inputPasswordPanel}
                  placeholder=""
                />
                 <label className={styles.passwordLabelInput}>Confirm New Password</label>
              </div>
              <hr></hr>
              <div className={'d-flex flex-column justify-content-center w-100 container-button-group'}>
                <button className="btn btn-primary w-100" style={{}}>
                  Submit
                </button>
              </div>
            </div>


          </form>
        </div>
      </div>
    );
  }
}

export default ChangePassword;
