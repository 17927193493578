import React, { useState, useEffect, useRef } from 'react';
import Header from '../Organisms/Header';
import LeftSection from '../Organisms/LeftSection';
import Breadcrumbs from '../Atoms/Breadcrumbs';
import ProjectsPage from './ProjectsLayout';
import { useSelector, useDispatch } from "react-redux";
import IndividualInformation from '../ServiceProviders/components/individualInformation';
import FormField from '../Molecules/FormField';
import AccountLayout from './AccountLayout';
import DashboardLayout from './DashboardLayout';
import ModalSubscriptionAlert from '../Organisms/ModalSubscriptionAlert';
import useSubscriptions from '../../hooks/useSubscriptions';
import useSubscriptionWatcher from '../../hooks/useSubscriptionWatcher';
import { showGlobalMessage, updateGlobalMessage } from '../../Actions/messageAlert';
import ModalGlobalNotification from '../Organisms/ModalGlobalNotification';
const PageLayout = ({ logout, updateSubscription }) => {

    const token = useSelector((state) => state.token.token);
    const user = useSelector((state) => state.serviceProvider.serviceProvider);
    const message = useSelector((state) => state.globalMessage.globalMessage);
    const activeGlobalAlert = useSelector((state) => state.globalMessage.showMessage);
    const dispatch = useDispatch();
    const [selectedPage, setSelectedPage] = useState('Dashboard');
    const [selectedPhase, setSelectedPhase] = useState();
    const [isSelectedProject, setIsSelectedProject] = useState(0);
    const [projectName, setProjectName] = useState("");
    const [selectedProject, setSelectedProject] = useState();
    const [breadPath, setBreadPath] = useState(['Home']);
    const [paymentProgress, setPaymentProgress] = useState(user.subscription.status !== 'active' ? true : false);
    const { subscription, getSubscription } = useSubscriptions(token);

    const menuItems = [
        { label: 'Home', onClick: () => setSelectedPage('Dashboard') },
        { label: 'Projects', onClick: () => setSelectedPage('Projects') },
        { label: 'Account', onClick: () => setSelectedPage('Account') },
    ];

    const menuItemsLeft = [
        { id: 1, label: 'Feasibility Phase', onClick: () => handleSelectMenuLeftItems('Feasibility Phase') },
        { id: 2, label: 'Planning Phase', onClick: () => handleSelectMenuLeftItems('Planning Phase') },
        { id: 3, label: 'Construction Phase', onClick: () => handleSelectMenuLeftItems('Construction Phase') },
        { id: 4, label: 'Handover / Closeout Phase', onClick: () => handleSelectMenuLeftItems('Handover / Closeout Phase') },
        { id: 5, label: 'Work Breakdown Structure', onClick: () => handleSelectMenuLeftItems('Work Breakdown Structure') },
    ];

    const menuItemsAccount = [
        { id: 1, label: 'Profile Information', onClick: () => handleSelectMenuLeftItems('Profile Information') },
        { id: 2, label: 'Notifications', onClick: () => handleSelectMenuLeftItems('Notifications') },
        { id: 3, label: 'Logout', onClick: () => handleSelectMenuLeftItems('Logout') },
    ]

    const menuItemsHome = [
        { id: 1, label: 'Projects', onClick: () => setSelectedPage('Projects') },
        { id: 2, label: 'Account', onClick: () => setSelectedPage('Account') }
    ]

    const rightIcons = [
        { src: '/bell-icon.png', alt: 'Notifications', onClick: () => console.log('Bell clicked') },
        { src: '/user-icon.png', alt: 'User', onClick: () => console.log('User clicked') },
    ];

    // useEffect(() => {

    //     if (paymentProgress) {
    //         intervalRef.current = setInterval(async () => {
    //             await getSubscription();
    //             const blockerExists = document.querySelector('.modal-subscription-alert');
    //             if (!blockerExists) {
    //                 window.location.href = '/vendor/dashboard';
    //             }
    //         }, 3000); // 60000 ms = 1 minuto
    //     }

    //     // Cuando isActive cambia o el componente se desmonta, limpiamos
    //     return () => {
    //         if (intervalRef.current) {
    //             clearInterval(intervalRef.current);
    //             intervalRef.current = null;
    //         }
    //     };

    // }, [paymentProgress])

    useSubscriptionWatcher({
        paymentProgress,
        getSubscription: getSubscription
    });

    useEffect(() => {
        if (subscription.status === "active" && paymentProgress) {
            updateSubscription(subscription);
            dispatch(showGlobalMessage(true));
            dispatch(updateGlobalMessage("You're all set! Your payment went through, and you can now enjoy all the perks of your Basic membership."));
        }
    }, [subscription])


    useEffect(() => {
        if (isSelectedProject) setSelectedPhase('Feasibility Phase');
    }, [isSelectedProject])

    useEffect(() => {
        setBreadPath(['Home', selectedPage]);

        if (selectedPage === 'Account') {
            handleSelectMenuLeftItems('Profile Information');
            setIsSelectedProject(0);
            setSelectedPhase();
            setSelectedProject();
            setProjectName();
        }
        if (selectedPage === 'Dashboard') {
            setIsSelectedProject(0);
            setSelectedPhase();
            setSelectedProject();
            setProjectName();
        }
    }, [selectedPage])

    useEffect(() => {
        if (selectedPage === 'Projects' && selectedPhase) setBreadPath(['Home', selectedPage, projectName, selectedPhase]);
        if (selectedPage === 'Account' && selectedPhase) setBreadPath(['Home', selectedPage, selectedPhase]);
    }, [selectedPhase])

    useEffect(() => {


        switch (breadPath.length) {
            case 2:
                if (selectedPage === 'Projects') {
                    setIsSelectedProject(false);
                    setSelectedPhase('');
                }
                if (selectedPage === 'Account') {
                    handleSelectMenuLeftItems('Profile Information');
                }
                break;

            default:
                break;
        }
    }, [breadPath])

    const handlePathChange = (paths) => {
        if (paths.length === 1 && paths[0] === 'Home') {
            setSelectedPage('Dashboard');
        };
        setBreadPath(paths);
    };

    const handleSelectMenuLeftItems = (item) => {
        if (selectedPage === 'Projects' && isSelectedProject) {
            setSelectedPhase(item);
            console.log('entre');

        }

        if (selectedPage === 'Account') {
            setSelectedPhase(item);
            if (item === 'Logout') {
                logout();
            }
        }
    }

    const handleSelectedRecentProject = (item) => {

        setIsSelectedProject(item.id);
        setSelectedProject(item);
        setProjectName(item.name);
        setSelectedPage('Projects');
        setSelectedPhase('Feasibility Phase');
    }



    const renderPageContent = () => {
        switch (selectedPage) {
            case 'Dashboard':
                return (
                    <DashboardLayout token={token} currentPage={selectedPage} handleSelectedRecentProject={handleSelectedRecentProject} />
                );
            case 'Account':
                return (
                    <AccountLayout
                        token={token}
                        serviceProvider={user}
                        panel={selectedPhase}
                        changeMenu={handleSelectMenuLeftItems}
                    />
                );
            case 'Projects':
                return (
                    <ProjectsPage
                        selectedPage={selectedPage}
                        selectedPhase={selectedPhase}
                        setIsSelectedProject={setIsSelectedProject}
                        selectedProject={selectedProject}
                        setProjectName={setProjectName}
                        token={token}
                        serviceProvider={user}
                    />
                );
            default:
                return <h1>Página no encontrada</h1>;
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSelectedPhase(value);

    }

    const handleActivePaymentProgress = () => {
        setPaymentProgress(true);
    }
    const closeGlobalNotification = () => {
        dispatch(showGlobalMessage(false));
        dispatch(updateGlobalMessage(""));
    }

    return (
        <div className="d-flex flex-column" style={{ marginBottom: '15px' }}>
            <Header
                logoSrc="/logo.png"
                logoAlt="App Logo"
                menuItems={menuItems}
                selectedPage={selectedPage}
                rightIcons={rightIcons}
                logout={logout} />

            <div className="d-flex ">
                <ModalSubscriptionAlert show={user.subscription.status !== 'active' ? true : false} stripeUrl={user.subscription?.update_payment_method_url} handleActivePaymentProgress={handleActivePaymentProgress} />
                <ModalGlobalNotification show={activeGlobalAlert} message={message} close={closeGlobalNotification} />
                <div className='d-none d-md-block' style={{ width: '20%', position: 'relative' }}>

                    <LeftSection
                        menuItems={selectedPage === 'Account' ? menuItemsAccount : selectedPage === 'Dashboard' ? menuItemsHome : menuItemsLeft}
                        selectedPhase={selectedPhase}
                        isSelectedProject={isSelectedProject}
                        subscription={user?.subscription}
                        userEmail={user?.email_address}
                        typePage={selectedPage}
                    />
                </div>

                <div className="flex-grow-1 px-4 pt-1" style={{ background: '#f9f9f9', width: '80%' }}>
                    <Breadcrumbs paths={breadPath} onPathChange={handlePathChange} />
                    {(isSelectedProject && selectedPage === 'Projects') ?
                        <div className='d-block d-md-none'>
                            <FormField
                                type="select"
                                label=""
                                name="main_building_use"
                                value={selectedPhase}
                                onChange={handleChange}
                                customValue='Select Construction phase'
                                options={menuItemsLeft}
                            />
                        </div>
                        :
                        null
                    }
                    {renderPageContent()}

                </div>
            </div>
        </div>
    );
};

export default PageLayout;

