import { SHOW_MESSAGE, UPDATE_MESSAGE } from "../Constants/actionTypes.jsx";

const initialState = {
  showMessage: false,
  globalMessage: "",
};

export default (state = initialState, action) => {  
  switch (action.type) {
    case SHOW_MESSAGE:      
      return Object.assign({}, state, {
        showMessage: action.payload,
      });
    case UPDATE_MESSAGE:
      return Object.assign({}, state, {
        globalMessage: action.payload,
      });
    default:
      return state;
  }
};
