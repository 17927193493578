import React from 'react';
import Button from '../Atoms/Button';

const ButtonGroup = ({ onCancel }) => {
    return (
        <div className="d-flex flex-column flex-md-row justify-content-end container-button-group">
            <Button label="Save" type="submit" variant="primary" />
            <Button label="Cancel" onClick={onCancel} type="button" variant="secondary" />
        </div>
    );
};

export default ButtonGroup;
