import React, { useState, useEffect } from 'react';
import Header from '../Organisms/Header';
import LeftSection from '../Organisms/LeftSection';
import Breadcrumbs from '../Atoms/Breadcrumbs';
import ProjectsPage from './ProjectsLayout';
import { useSelector, useDispatch } from "react-redux";
import IndividualInformation from '../ServiceProviders/components/individualInformation';
import FormField from '../Molecules/FormField';
import MembeshipItems from '../Organisms/WorkFlow/MembeshipItems';
import ModalEntrance from '../Organisms/WorkFlow/ModalEntrance';
import { Modal } from 'react-bootstrap';
const PageLayoutFree = ({ project, isUserLoggedIn, isSpLoggedIn, handleServiceProviderLogin, uuid, stopTour }) => {

    const [selectedPage, setSelectedPage] = useState('Projects');
    const [selectedPhase, setSelectedPhase] = useState('');
    const [isSelectedProject, setIsSelectedProject] = useState(0);
    const [projectName, setProjectName] = useState("");
    const [breadPath, setBreadPath] = useState(['Home']);

    const [timeRemaining, setTimeRemaining] = useState(30 * 60);

    /**
     * Modal for signup
     */
    const [showSubscriptionWall, setShowSubscriptionWall] = useState(false);
    const [showDemoAlert, setShowDemoAlert] = useState(false);
    const [showModalEntrance, setShowModalEntrance] = useState(false);
    const [showStripeWall, setShowStripeWall] = useState(false);

    const token = useSelector((state) => state.token.token);
    const user = useSelector((state) => state.serviceProvider.serviceProvider);



    const menuItemsLeft = [
        { id: 1, label: 'Feasibility Phase', onClick: () => setSelectedPhase('Feasibility Phase') },
        { id: 2, label: 'Planning Phase', onClick: () => setSelectedPhase('Planning Phase') },
        { id: 3, label: 'Construction Phase', onClick: () => setSelectedPhase('Construction Phase') },
        { id: 4, label: 'Handover / Closeout Phase', onClick: () => setSelectedPhase('Handover / Closeout Phase') },
        { id: 5, label: 'Work Breakdown Structure', onClick: () => setSelectedPhase('Work Breakdown Structure') },
    ];

    useEffect(() => {
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(el => {
            sessionStorage.removeItem('frameworks_items_F' + el);
        })
        sessionStorage.removeItem('frameworks');

    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeRemaining((prev) => {
                if (prev <= 1) {
                    window.location.reload(); // Refresh the page
                    return 30 * 60; // Reset timer
                }
                return prev - 1;
            });
        }, 1000); // Decrease time every second

        return () => clearInterval(interval); // Cleanup interval on unmount
    }, []);

    useEffect(() => {
        if (timeRemaining === 10 * 60) {

        }
    }, [timeRemaining]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes.toString().padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
    };

    useEffect(() => {
        if (isSelectedProject) {
            setSelectedPhase('Feasibility Phase');
            stopTour();
        }


    }, [isSelectedProject])

    useEffect(() => {
        setBreadPath(['Home', selectedPage]);
    }, [selectedPage])

    useEffect(() => {
        if (selectedPhase) setBreadPath(['Home', selectedPage, projectName, selectedPhase]);
    }, [selectedPhase])

    useEffect(() => {
        switch (breadPath.length) {
            case 2:
                setIsSelectedProject(false);
                setSelectedPhase('');
                break;

            default:
                break;
        }
    }, [breadPath])

    const handlePathChange = (paths) => {
        if (paths.length === 1 && paths[0] === 'Home') return;
        setBreadPath(paths);
    };



    const renderPageContent = () => {
        switch (selectedPage) {
            case 'Dashboard':
                return <h1>Hola Dashboard</h1>;
            case 'Account':
                return (
                    <IndividualInformation
                        token={token}
                        serviceProvider={user}
                    />
                );
            case 'Projects':
                return (
                    <ProjectsPage
                        selectedPage={selectedPage}
                        selectedPhase={selectedPhase}
                        setIsSelectedProject={setIsSelectedProject}
                        setProjectName={setProjectName}
                        token={token}
                        serviceProvider={user}
                        freeProject={project}
                        handleShowSubscriptionsWall={handleShowSubscriptionsWall}
                    />
                );
            default:
                return <h1>Página no encontrada</h1>;
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSelectedPhase(value);

    }

    const handleShowSubscriptionsWall = (alert = true) => {
        setShowSubscriptionWall(!showSubscriptionWall);
        setShowDemoAlert(alert);
    }

    const handleManageModalEntrance = (toStripe) => {
        setShowSubscriptionWall(false);
        setShowModalEntrance(true);
        setShowStripeWall(toStripe);
    }

    return (
        <>
            <Modal show={showSubscriptionWall} size="lg" centered onHide={() => setShowSubscriptionWall(false)} style={{ backgroundColor: "#000000c7", zIndex:"1060" }}>
                <Modal.Body className='m-0 p-0'>
                    <div className="modal-card-head"><span onClick={() => setShowSubscriptionWall(false)} style={{ cursor: 'pointer' }}>X</span></div>
                    {showDemoAlert &&
                        <div className='alert alert-warning m-0'>
                            This category is not available in trial mode. Please sign in or sign up to access it.
                        </div>
                    }

                    <section className={"modal-card-body membership-modal radius-0"}>
                        <MembeshipItems handleShowModalEntrance={handleManageModalEntrance} />
                    </section>
                </Modal.Body>
            </Modal>

            {
                showModalEntrance &&
                <div className={'modal small-popup ' + (showModalEntrance && 'is-active')}>
                    <div className="modal-background" />

                    <div className={"modal-card modal-entrance"}>
                        <section className={"modal-card-body " + (showModalEntrance ? 'entrance' : '')}>
                            <div className='div-close-button'>
                                <button onClick={() => setShowModalEntrance(false)}>X</button>
                            </div>
                            <ModalEntrance
                                isUserLoggedIn={isUserLoggedIn}
                                isSpLoggedIn={isSpLoggedIn}
                                handleServiceProviderLogin={handleServiceProviderLogin}
                                projectInformation={project}
                                message={""}
                                showStripeWall={showStripeWall}
                            >
                            </ModalEntrance>
                        </section>
                    </div>
                </div>

            }

            <div className="d-flex flex-column" style={{ width: '100%' }}>
                <div className="d-flex ">
                    <div className='d-none d-md-block' style={{ width: '20%' }}>

                        <LeftSection
                            menuItems={menuItemsLeft}
                            selectedPhase={selectedPhase}
                            isSelectedProject={isSelectedProject}
                            subscription={user?.subscription}
                            userEmail={user?.email_address}
                            handleShowSubscriptionsWall={handleShowSubscriptionsWall}
                        />
                    </div>

                    <div className="flex-grow-1 px-4 pt-1" style={{ background: '#f9f9f9', width: '80%' }}>
                        {timeRemaining <= 10 * 60 &&
                            <h4 style={{ width: "100%", padding: "5px", textAlign: "center", backgroundColor: "#f9b62f", borderRadius: "5px", fontFamily: "Inter, serif", fontSize: "20px", }}>The page will refresh in {formatTime(timeRemaining)}</h4>
                        }
                        <Breadcrumbs paths={breadPath} onPathChange={handlePathChange} />
                        {isSelectedProject &&
                            <div className='d-block d-md-none'>
                                <FormField
                                    type="select"
                                    label=""
                                    name="main_building_use"
                                    value={selectedPhase}
                                    onChange={handleChange}
                                    customValue='Select Construction phase'
                                    options={menuItemsLeft}
                                />
                            </div>
                        }
                        {renderPageContent()}

                    </div>
                </div>
            </div>
        </>

    );
};

export default PageLayoutFree;

