import React, { useState, useReducer, useEffect } from 'react'
import FrameworkHeader from './FrameworkHeader'
import WorkFlowOrganism from './WorkFlow/Index';
import PreDevelopment from './Phases/PreDevelopment';
import PreConstruction from './Phases/PreConstruction';
import Construction from './Phases/Construction';
import PostConstruction from './Phases/PostConstruction';
import axios from 'axios';
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { BASE_URL } from '../../Constants/serverConfig';
import Loader from '../Loader/loader';


const TOUR_STEPS = [
  {
    target: ".tour-add-framework-1",
    content: "Clic on the 'Add' button to assign it to the project",
    disableBeacon: true,
    disableOverlayClose: true,
    hideFooter: true,
    placement: 'top',
    spotlightClicks: true,
    styles: {
      tooltip: { padding: 2, fontSize: 14 },
      tooltipContainer: { textAlign: 'center' },
    }
  },
  // {
  //   target: ".tour-edit-framework-1",
  //   content: "Clic to edit the framework and select the elements that compose it.",
  //   disableBeacon: true,
  //   disableOverlayClose: true,
  //   hideCloseButton: true,
  //   hideFooter: true,
  //   placement: 'top',
  //   spotlightClicks: true,
  //   styles: {
  //     tooltip: { padding: 2, fontSize: 14 },
  //     tooltipContainer: { textAlign: 'center' },
  //   }
  // },
  {
    target: ".tour-select-framework-items",
    content: "Select some items and save your progress.",
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    hideFooter: true,
    placement: 'top',
    spotlightClicks: true,
    styles: {
      tooltip: { padding: 2, fontSize: 14 },
      tooltipContainer: { textAlign: 'center' },

    }
  },
  {
    target: ".tour-download-framework",
    content: (
      <span>You can download your progress from the menu that appears when you clic on the button <b style={{
        border: '1px solid gray',
        padding: '1px 10px',
        borderRadius: '3px'
      }}>...</b>.</span>),
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    hideFooter: true,
    placement: 'top',
    spotlightClicks: true,
    styles: {
      tooltip: { padding: 2, fontSize: 14 },
      tooltipContainer: { textAlign: 'center' },

    }
  }

];

const INITIAL_STATE = {
  key: new Date(), // This field makes the tour to re-render when we restart the tour
  run: false,
  continuous: true,
  loading: false,
  stepIndex: 0,
  steps: TOUR_STEPS
};

// Reducer will manage updating the local state
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "START":
      return { ...state, run: true };
    case "RESET":
      return { ...state, stepIndex: 0 };
    case "STOP":
      return { ...state, run: false };
    case "NEXT_OR_PREV":
      return { ...state, ...action.payload };
    case "RESTART":
      return {
        ...state,
        stepIndex: 0,
        run: true,
        loading: false,
        key: new Date()
      };
    default:
      return state;
  }
};

function ProjectPhase({ selectedPhase, project, editProject, token, serviceProvider, subscription = { plan: 'no-plan' }, handleShowSubscriptionsWall }) {

  const [projectFrameworks, setProjectFrameworks] = useState([]);
  const [projectFrameworksItems, setProjectFrameworksItems] = useState([]);
  const [notification, setNotification] = useState({ show: false, message: '' });
  const [showLoader, setShowLoader] = useState(true);
  const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);
  const [currentStep, setCurrentStep] = useState(0);

 
  useEffect(() => {
    if (notification.show) {
      const timer = setTimeout(() => {
        setNotification({ show: false, message: "" });
      }, 5000); // 10 segundos

      return () => clearTimeout(timer);
    }
  }, [notification.show])


  const saveCustomFrameworks = (framework) => {

    if (subscription.plan === 'no-plan') {
      let value = JSON.parse(sessionStorage.getItem('frameworks')) ?? [];
      value.push(framework);
      sessionStorage.setItem('frameworks', JSON.stringify(value));
      setCurrentStep(1);
      return;
    }

    axios.post(`${BASE_URL}/project/frameworks`,
      {
        framework: framework,
        project_id: project.id
      }
    )
      .then((response) => {
        setNotification({ show: true, message: `The Framework ${framework.name} was added` })
        return;
      })
      .catch((error) => {

      });


  }

  const getCustomFrameworks = (framework_id) => {

    if (subscription.plan === 'no-plan') {
      let value = JSON.parse(sessionStorage.getItem('frameworks')) ?? [];

      let frameworks = value.map((item) => {
        let valueItems = JSON.parse(sessionStorage.getItem('frameworks_items_' + item.id)) ?? [];
        return {
          ...item,
          total_items: valueItems.length
        }

      })

      setProjectFrameworks(frameworks)
      return;
    }
 
    axios.get(`${BASE_URL}/project/${project.id}/frameworks/${framework_id}`)
      .then((response) => {
        setProjectFrameworks(response.data.data)
      })
      .catch((error) => {

      });

  }
  const removeCustomFrameworks = async (framework_id, password) => {

    let response = { status: "" };
    if (subscription.plan === 'no-plan') {
      let value = JSON.parse(sessionStorage.getItem('frameworks')) ?? [];
      const filteredFramework = value.filter(item => item.id !== framework_id);
      sessionStorage.setItem('frameworks', JSON.stringify(filteredFramework));
      return;
    }
    await axios.delete(`${BASE_URL}/project/${project.id}/frameworks/${framework_id}`, {
      data: { password: password }, // Cuerpo de la solicitud
      headers: {
        "Content-Type": "application/json", // Especifica el formato del cuerpo
      },
    })
      .then((res) => {
        setNotification({ show: true, message: `The Framework was removed` })
        setProjectFrameworks(res.data.data)
        response.status = 'success';
      })
      .catch((error) => {
        response.status = 'failed';

      });

    return response;

  }

  const getCustomFrameworkItems = (framework_parent) => {

    if (subscription.plan === 'no-plan') {
      let value = JSON.parse(sessionStorage.getItem('frameworks_items_' + framework_parent)) ?? [];
      const filteredFramework = value.filter(item => item.framework == framework_parent);

      setProjectFrameworksItems(filteredFramework);

      return;
    }
    axios.get(`${BASE_URL}/project/${project.id}/frameworks/${framework_parent}/items`
    )
      .then((response) => {
        setProjectFrameworksItems(response.data.data)
      })
      .catch((error) => {

      });
  }

  const saveCustomFrameworkItems = (framework_items, framework, main_framework, props = {}) => {

    if (subscription.plan === 'no-plan') {
      //handleShowSubscriptionsWall();
      let value = [];

      framework_items.map(({ id, parent_id }) => (
        value.push({
          id: id,
          checked: true,
          parent_id: parent_id,
          framework: framework.id,
        })));


      sessionStorage.setItem('frameworks_items_' + framework.id, JSON.stringify(value));

      let newValue = JSON.parse(sessionStorage.getItem('frameworks_items_' + framework.id)) ?? [];
      const filteredFramework = newValue.filter(item => item.framework == framework.id);

      setProjectFrameworksItems(filteredFramework);

      setCurrentStep(3);
      nextStep(3);
      return;
    }
    framework_items = framework_items.map(({ id, parent_id }) => ({
      framework_element_id: id,
      checked: true,
      framework_element_parent_id: parent_id,
      project_id: project.id,
      framework: framework.id,
    }));

    axios.post(`${BASE_URL}/project/frameworks/items`,
      {
        framework_items, framework_items,
        project_id: project.id,
        framework: framework.id,
        props: props

      }
    )
      .then((response) => {
        setProjectFrameworksItems(response.data.data);
      })
      .catch((error) => {

      });
  }

  const saveCustomFrameworkItemsNote = (framework, framework_item_id, note) => {
    axios.post(`${BASE_URL}/project/frameworks/items/note`,
      {
        framework_item_id, framework_item_id,
        project_id: project.id,
        framework: framework.id,
        note: note
      }
    )
      .then((response) => {
        setProjectFrameworks(response.data.data)
      })
      .catch((error) => {

      });


  }

  const getCustomFrameworkItemsNote = async (framework, framework_item_id) => {
    let note = "";
    await axios.get(`${BASE_URL}/project/${project.id}/frameworks/${framework.id}/items/${framework_item_id}/note`)
      .then((response) => {
        note = response.data.data;
      })
      .catch((error) => {

      });

    return note;
  }

  const downloadFrameworkFile = (framework_id, name) => {

    if (subscription.plan === 'no-plan') {

      // handleShowSubscriptionsWall();
      let value = sessionStorage.getItem('frameworks_items_' + framework_id) ?? [];

      axios.post(`${BASE_URL}/project/frameworks/public/generate-file`,
        {
          framework_id: framework_id,
          framework_name: name,
          items: value
        }
      )
        .then((response) => {
          let fileName = response.data.data;
          axios.get(BASE_URL + '/public/project/download-report/' + fileName, {
            responseType: 'blob'
          }).then(response => {

            const url = window.URL.createObjectURL(new Blob([response.data]));

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName + '.xlsx');
            document.body.appendChild(link);
            link.click();
          }).catch(err => {
            console.log(err);
          });

        })
        .catch((error) => {

        });
      return;
    }

    axios.post(`${BASE_URL}/project/frameworks/generate-file`,
      {
        framework_id: framework_id,
        project_id: project.id
      }
    )
      .then((response) => {
        let fileName = response.data.data;
        axios.get(BASE_URL + '/public/project/download-report/' + fileName, {
          responseType: 'blob'
        }).then(response => {

          const url = window.URL.createObjectURL(new Blob([response.data]));

          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName + '.xlsx');
          document.body.appendChild(link);
          link.click();
        }).catch(err => {
          console.log(err);
        });

      })
      .catch((error) => {

      });
  }

  const setTourViewed = () => {
    localStorage.setItem("hiddenTour", "1");
  };

  const callback = data => {
    const { action, index, type, status } = data;

    if (
      // If close button clicked, then close the tour
      action === ACTIONS.CLOSE ||
      // If skipped or end tour, then close the tour
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      setTourViewed();
      dispatch({ type: "STOP" });
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      // Check whether next or back button click and update the step.
      dispatch({
        type: "NEXT_OR_PREV",
        payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) }
      });
    }
  };

  const startTour = () => {
    // Start the tour manually
    dispatch({ type: "START" });
  };
  const nextStep = (step) => {
    // Start the tour manually
    dispatch({
      type: "NEXT_OR_PREV",
      payload: { stepIndex: step } // Establece el índice del paso al segundo paso
    });
  };

  const stopTour = () => {
    // Start the tour manually
    dispatch({ type: "STOP" });
  };


  const renderPageContent = (selectedPhase) => {
    switch (selectedPhase) {
      case 'Feasibility Phase':
        return (<PreDevelopment
          projectFrameworks={projectFrameworks}
          projectFrameworksItems={projectFrameworksItems}
          saveFramework={saveCustomFrameworks}
          getCustomFrameworks={getCustomFrameworks}
          removeCustomFrameworks={removeCustomFrameworks}
          saveCustomFrameworkItems={saveCustomFrameworkItems}
          saveCustomFrameworkItemsNote={saveCustomFrameworkItemsNote}
          getCustomFrameworkItemsNote={getCustomFrameworkItemsNote}
          getCustomFrameworkItems={getCustomFrameworkItems}
          downloadFramework={downloadFrameworkFile}
          handleShowSubscriptionsWall={handleShowSubscriptionsWall}
          subscription={subscription}
          startTour={startTour}
          nextStep={nextStep}
          stopTour={stopTour}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />);
      case 'Planning Phase':
        return (<PreConstruction
          projectFrameworks={projectFrameworks}
          projectFrameworksItems={projectFrameworksItems}
          saveFramework={saveCustomFrameworks}
          getCustomFrameworks={getCustomFrameworks}
          removeCustomFrameworks={removeCustomFrameworks}
          saveCustomFrameworkItems={saveCustomFrameworkItems}
          saveCustomFrameworkItemsNote={saveCustomFrameworkItemsNote}
          getCustomFrameworkItemsNote={getCustomFrameworkItemsNote}
          getCustomFrameworkItems={getCustomFrameworkItems}
          downloadFramework={downloadFrameworkFile}
          handleShowSubscriptionsWall={handleShowSubscriptionsWall}
          subscription={subscription}
        />);
      case 'Construction Phase':
        return (<Construction
          projectFrameworks={projectFrameworks}
          projectFrameworksItems={projectFrameworksItems}
          saveFramework={saveCustomFrameworks}
          getCustomFrameworks={getCustomFrameworks}
          removeCustomFrameworks={removeCustomFrameworks}
          saveCustomFrameworkItems={saveCustomFrameworkItems}
          saveCustomFrameworkItemsNote={saveCustomFrameworkItemsNote}
          getCustomFrameworkItemsNote={getCustomFrameworkItemsNote}
          getCustomFrameworkItems={getCustomFrameworkItems}
          downloadFramework={downloadFrameworkFile}
          handleShowSubscriptionsWall={handleShowSubscriptionsWall}
          subscription={subscription}
        />);
      case 'Handover / Closeout Phase':
        return (<PostConstruction
          projectFrameworks={projectFrameworks}
          projectFrameworksItems={projectFrameworksItems}
          saveFramework={saveCustomFrameworks}
          getCustomFrameworks={getCustomFrameworks}
          removeCustomFrameworks={removeCustomFrameworks}
          saveCustomFrameworkItems={saveCustomFrameworkItems}
          saveCustomFrameworkItemsNote={saveCustomFrameworkItemsNote}
          getCustomFrameworkItemsNote={getCustomFrameworkItemsNote}
          getCustomFrameworkItems={getCustomFrameworkItems}
          downloadFramework={downloadFrameworkFile}
          handleShowSubscriptionsWall={handleShowSubscriptionsWall}
          subscription={subscription}
        />);
      case 'Work Breakdown Structure':
        return <WorkFlowOrganism
          uuid={window.self.crypto.randomUUID()}
          isChildren={true}
          showFrameworkPanel={true}
          data={project}
          pathFramework={JSON.parse(project?.project_item_selection || "[]")}
          pathSupplementary={project.supplementary_list}
          token={token}
          serviceProvider={serviceProvider}
          subscription={subscription}
          editProject={editProject}
          loaderWorkflow={setShowLoader}
          handleShowSubscriptionsWall={handleShowSubscriptionsWall}
        />;
      case 'Account':
        return <h1>Account</h1>;
      default:
        return <h1>Default</h1>
    }
  };



  return (
    <div className='tour-add-framework-1'>
      <JoyRide
        {...tourState}
        callback={callback}
        showSkipButton={true}
        styles={{
          options: { zIndex: 10000 }
        }}
        locale={{
          last: "End tour"
        }}
      />

      {notification.show &&
        <div className='alert alert-success' role='alert'>
          {notification.message}
        </div>
      }
      {
        (showLoader && selectedPhase === 'Work Breakdown Structure') &&
        <Loader text="Loading..." open={showLoader} />
      }
      {renderPageContent(selectedPhase)}
    </div>
  )
}

export default ProjectPhase