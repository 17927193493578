/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
//import { compose } from "recompose";
import { compose } from "redux";
import querystring from "querystring";
import axios from "axios";
import { BASE_URL } from "../../Constants/serverConfig";
import Header from "../Header/header";
import Footer from "../Footer";
import NewHeader from "../Header/NewHeader";
import NewFooter from "../Footer/NewFooter";
import { TOKEN } from "../../Constants/localStorageKeys";
import SecureLS from "secure-ls";
class Thanks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailVerification: "",
      paymentStatus: "",
      status: "",
      customMessage: "",
      emailOrUsername: "",
      reConfirmationSent: false,
      showError: false,
      errorMessage: "",
    };
  }

  componentDidMount() {
    const { location, isSpLoggedIn, handleLogout, updateSubscription } = this.props;
    if (location.search !== "") {
      let params = querystring.parse(location.search);
      console.log('logueado', isSpLoggedIn);

      let token = params["?token"];
      let status;
      if (token === undefined) {
        status = params["?status"];
      } else {
        status = params["status"];
      }
      let paymentStatus = params["payment"];

      if (token !== undefined) {
        axios
          .get(`${BASE_URL}/email/confirm?token=${token}`)
          .then((response) => {
            if (response.status === 200) {
              if (status === "verify") {
                //Case when email verification is successful
                this.setState({
                  status,
                  emailVerification: "success",
                  paymentStatus: undefined,
                  customMessage:
                    "Thank you for confirming the registration in PropAGILE, you can now log in and continue filling out your personal or company information.",
                });
              } else if (status === "change") {
                //Case when email change verification is successful
                this.setState({
                  status,
                  emailVerification: "success",
                  paymentStatus: undefined,
                  customMessage:
                    "Your email has changed successfully. Please log in again to use PropAGILE services.",
                });
                if (isSpLoggedIn) {
                  handleLogout();
                }
              }
            }
          })
          .catch((error) => {
            //Case when email verification fails in either change or verify
            if (error.response.status === 500) {
              this.setState({
                status,
                emailVerification: "failed",
                paymentStatus: undefined,
                customMessage:
                  "Confirmation of your account was not possible. Please try again, request your account confirmation link again or contact support",
              });
            }
          });
        return;
      }
      //Case when Trial is activated.
      if (paymentStatus === "success" && status === "trial") {
        this.setState({
          status,
          paymentStatus,
          customMessage:
            "Thank you for get our trial membership. For changes to be reflected, you must log in again.",
        });
        if (isSpLoggedIn) {
          handleLogout();
        }
      }
      //Cases when sign up is new.


      if (paymentStatus === "success" && status === "new" && !isSpLoggedIn) {
        this.setState({
          status,
          paymentStatus,
          customMessage:
            "Thank you for signing up, we have sent you an email for confirmation, Please check your email.",
        });

      }

      if (paymentStatus === "success" && status === "new" && isSpLoggedIn) {

        const localStore = new SecureLS();
        let token = localStore.get(TOKEN);

        axios
          .get(`${BASE_URL}/subscription`,
            {
              headers: {
                authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              }
            },
          )
          .then((response) => {
            console.log(response.data);
            if (response.data.status === 'Success') {
              updateSubscription(response.data.data);
              this.setState({
                status,
                paymentStatus,
                customMessage:
                  "Upgrade",
              });
            }else{
              this.setState({
                status,
                paymentStatus,
                customMessage:
                  "Failed",
              });
            }
          })
          .catch((error) => {
            this.setState({
              status,
              paymentStatus,
              customMessage:
                "Error",
            });
          });



      }

      if (paymentStatus === "failed" && status === "new") {
        this.setState({
          status,
          paymentStatus,
          customMessage:
            "Thank you for registering to PropAGILE. Your account has been created but your payment was not successful. We have sent you a notification for your account confirmation, please check your email. Within the PropAGILE dashboard you can buy a membership or upgrade to a superior one.",
        });
      }
      //Case when user upgrades membership and payment is successful.
      if (paymentStatus === "success" && status === "upgrade") {
        handleLogout();
        this.setState({
          status,
          paymentStatus,
          customMessage:
            "Thank you for purchasing one of our memberships. For changes to be reflected, you must log in again.",
        });
      }
      //Case when user upgrades membership and payment is successful.
      if (paymentStatus === "failed" && status === "upgrade") {
        this.setState({
          status,
          paymentStatus,
          customMessage:
            "Your payment has failed, no change effected in your membership.",
        });
      }
    }
  }

  showError = (errorMessage) => {
    this.setState({
      showError: true,
      errorMessage,
    });
  };

  clearError = () => {
    this.setState({
      showError: false,
      errorMessage: "",
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  sendReconfirmation = () => {
    const { emailOrUsername } = this.state;
    if (emailOrUsername.trim() === "") {
      this.showError("Please enter email or username");
      return;
    }
    axios
      .post(
        `${BASE_URL}/entrance/request-confirmation-email`,
        {
          input_data: emailOrUsername.trim(),
        },
        { withCredentials: true }
      )
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            reConfirmationSent: true,
            emailOrUsername: "",
          });
        }
      })
      .catch((error) => {
        this.showError(
          error.response !== undefined
            ? error.response.data
            : "Opps! Something went wrong please try again."
        );
      });
  };

  render() {
    const {
      emailVerification,
      paymentStatus,
      customMessage,
      status,
      emailOrUsername,
      reConfirmationSent,
      showError,
      errorMessage,
    } = this.state;
    const {
      isSpLoggedIn,
      handleLogout,
      serviceProvider,
      isUserLoggedIn,
    } = this.props;
    return (
      <div>
        {showError && (
          <div className="modal is-active custom-modal error-message">
            <div className="modal-background"></div>
            <div className="modal-content">
              <div className="error-message-modal">
                <button
                  onClick={this.clearError}
                  className="modal-close"
                ></button>
                <p>{errorMessage}</p>
                <a onClick={this.clearError} className="btn-fill">
                  OK
                </a>
              </div>
            </div>
          </div>
        )}
        {!!isSpLoggedIn || !!isUserLoggedIn ? (
          <Header
            isDark={true}
            isSpLoggedIn={isSpLoggedIn}
            handleLogout={handleLogout}
            serviceProvider={serviceProvider}
          />
        ) : (
          <NewHeader />
        )}
        <section className="form-wrapper">
          <div className="form-inner">
            <div className="form-main thankyou-main">
              <div className="thankyou-title clearfix">
                {/* When upgrading membership and payment is successful */}
                {status === "upgrade" && paymentStatus === "success" && (
                  <React.Fragment>
                    <h3>Thank You</h3>
                    <div className="tick-icon">
                      <i className="fa fa-check"></i>
                    </div>
                    <p>{customMessage}</p>
                    <div className="digital-upgrate-btn center-align-content">
                      <Link to="/vendor/lead/login" className="digital-btn">
                        Log out
                      </Link>
                    </div>
                  </React.Fragment>
                )}
                {/* When upgrading membership and payment failed */}
                {status === "upgrade" && paymentStatus === "failed" && (
                  <React.Fragment>
                    <h3>Thank You</h3>
                    <div className="tick-icon">
                      <i className="fa fa-check"></i>
                    </div>
                    <p>{customMessage}</p>
                    <div className="digital-upgrate-btn center-align-content">
                      <Link to="/"  className="digital-btn">
                        Go to Home
                      </Link>
                    </div>
                  </React.Fragment>
                )}
                {/* When Email changed and verification is successful */}
                {status === "change" &&
                  paymentStatus === undefined &&
                  emailVerification === "success" && (
                    <React.Fragment>
                      <h3>Thank You</h3>
                      <div className="tick-icon">
                        <i className="fa fa-check"></i>
                      </div>
                      <p>{customMessage}</p>
                      <div className="digital-upgrate-btn center-align-content">
                        <Link to="/" className="digital-btn">
                          Go to Home
                        </Link>
                      </div>
                    </React.Fragment>
                  )}
                {/* When Email changed and verification is failed */}
                {status === "change" &&
                  paymentStatus === undefined &&
                  emailVerification === "failed" && (
                    <React.Fragment>
                      <div className="tick-icon tick-yellow">
                        <i className="fa fa-exclamation-triangle"></i>
                      </div>
                      <p>{customMessage}</p>
                      <div className="clearfix"></div>
                      <div className="columns alert-text-box">
                        <div className="column is-9 form-group">
                          <label>Enter your email address / username</label>
                          <input
                            type="text"
                            value={emailOrUsername}
                            name="emailOrUsername"
                            placeholder="Enter your email address / username"
                            className="form-control"
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="column is-3">
                          <button
                            onClick={this.sendReconfirmation}
                            className="btn btn-primary"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                {/* When Email Verification is successful */}
                {status === "verify" &&
                  paymentStatus === undefined &&
                  emailVerification === "success" && (
                    <React.Fragment>
                      <h3>Thank You</h3>
                      <div className="tick-icon">
                        <img src="../images/email.png" width={200}></img>
                      </div>
                      <p>{customMessage}</p>
                      <div className="digital-upgrate-btn center-align-content">
                        <Link to="/vendor/lead/login" className="digital-btn">
                          Login
                        </Link>
                      </div>
                    </React.Fragment>
                  )}
                {/* When Email Verification fails */}
                {status === "verify" &&
                  paymentStatus === undefined &&
                  emailVerification === "failed" && (
                    <React.Fragment>
                      <div className="tick-icon tick-yellow">
                        <i className="fa fa-exclamation-triangle"></i>
                      </div>
                      <p>{customMessage}</p>
                      <div className="clearfix"></div>
                      <div className="columns alert-text-box">
                        <div className="column is-9 form-group">
                          <label>Enter your email address / username</label>
                          <input
                            type="text"
                            value={emailOrUsername}
                            name="emailOrUsername"
                            placeholder="Enter your email address / username"
                            className="form-control"
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="column is-3">
                          <button
                            onClick={this.sendReconfirmation}
                            className="btn btn-primary"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                {/* When Trial activated */}
                {status === "trial" && paymentStatus === "success" && (
                  <React.Fragment>
                    <h3>Thank You</h3>
                    <div className="tick-icon">
                      <i className="fa fa-check"></i>
                    </div>
                    <p>{customMessage}</p>
                    <div className="digital-upgrate-btn center-align-content">
                      <Link to="/vendor/lead/login" className="digital-btn">
                        Log out
                      </Link>
                    </div>
                  </React.Fragment>
                )}
                {/* When Payment is Sucess and sign up is new */}
                {status === "new" && paymentStatus === "success" && (
                  <React.Fragment>
                    <h3>Thank You</h3>
                    <div className="tick-icon">
                      <i className="fa fa-check"></i>
                    </div>
                    <p>{customMessage}</p>
                    <div className="digital-upgrate-btn center-align-content">
                      {isSpLoggedIn ?
                        <a href="/vendor/dashboard" className="digital-btn" >
                          Go to Dashboard
                        </a>
                        :
                        <Link to="/" className="digital-btn">
                          Go to Home
                        </Link>
                      }
                    </div>
                  </React.Fragment>
                )}
                {/* When Payment is Failed but sign up is new */}
                {status === "new" && paymentStatus === "failed" && (
                  <React.Fragment>
                    <h3>Thank You</h3>
                    <div className="tick-icon">
                      <i className="fa fa-check"></i>
                    </div>
                    <p>{customMessage}</p>
                    <div className="digital-upgrate-btn center-align-content">
                      <Link to="/" className="digital-btn">
                        Go to Home
                      </Link>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
            {reConfirmationSent && (
              <React.Fragment>
                <div className="clearfix"></div>
                <article
                  className="message is-success"
                  style={{ marginTop: 20 }}
                >
                  <div className="message-body">
                    Confirmation url has been sent to the inbox.
                  </div>
                </article>
              </React.Fragment>
            )}
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token.token,
    serviceProvider: state.serviceProvider.serviceProvider,
  };
};

export default compose(withRouter, connect(mapStateToProps, null))(Thanks);
