import React, { useState, useEffect } from 'react';
import ProjectCard from '../Atoms/ProjectCard';
import ModalProject from './Modal';
import Modal from 'react-bootstrap/Modal';
import Form from './Form';
import SubscriptionInformation from '../Molecules/SubscriptionInformation';
import InputText from '../Atoms/InputText';

function ModalConfirmRemove({ active, remove, close, setValue, value, alert = "" }) {
    return (
        <>
            <Modal size="lg" className="modal-remove" show={active} onHide={close} backdrop="static" centered>
                <Modal.Header  >
                </Modal.Header>
                <Modal.Body className=''>
                    <div className='delete-text'>
                        <img src="../images/warningAlert.png" width={300}></img>
                        <h4 className='d-none d-md-block'>Are you sure about deleting this project? It's permanent.</h4>
                        <h5 className='d-block d-md-none'>Are you sure about deleting this project? It's permanent.</h5>
                        <span>You must enter your password to confirm.</span>
                        {alert &&
                            <div className='alert alert-danger'>
                                <span>{alert}</span>
                            </div>
                        }
                        <div className='d-flex flex-row justify-content-center w-100 mt-3 input-modal-remove'>
                            <InputText label={""} name={"Password"} type={'password'} value={value} onChange={(e) => { setValue(e) }} placeholder={"Enter Password Account"} />
                        </div>
                        <div className='d-flex flex-column flex-md-row justify-content-end container-button-group mt-3'>
                            <button className='btn btn-primary mr-2' onClick={remove}>Confirm</button>
                            <button className='btn btn-secondary mr-2' onClick={close}>Cancel</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

const ProjectList = ({ projects, loading, setIsSelectedProject, setProjectName, selectProject, addProject, removeProject, editProject, subscription = { plan: 'no-plan' }, userEmail }) => {



    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showRemoveConfirm, setShowRemoveConfirm] = useState(false);
    const [formMode, setFormMode] = useState('create');
    const [project, setProject] = useState(false);
    const [canCreateProject, setCanCreateProject] = useState(false);
    const [inputPasswordValue, setInputPasswordValue] = useState("");
    const [textAlertRemove, setTextAlertRemove] = useState("");

    useEffect(() => {

        if ((subscription.plan === 'Basic' && projects.length < 3) || (subscription.plan === 'Free' && projects.length < 1)) {
            setCanCreateProject(true)
        }
        return () => {
            setCanCreateProject(false)
        }
    }, [projects])


    const handleOpenModal = (mode, project = null) => {
        setFormMode(mode);
        setProject(project)
        setIsModalOpen(true);
    }

    const handleCloseModal = () => {
        setProject("");
        setIsModalOpen(false);
    };

    const handleCloseRemoveModal = () => {
        setProject("");
        setShowRemoveConfirm(false);
    };

    const handleClickProject = (item) => {

        selectProject(item);
        setIsSelectedProject(item.id);
        setProjectName(item.name);
        setProject({
            id: item.id,
            name: item.name,
            zip_code: item.zip_code,
            gross_area: item.gross_area,
            work_type: item.work_type,
            main_building_use: item.main_building_use,
        })
    }

    const handleSave = (data) => {
        setProject(data);
        addProject(data);
        setIsModalOpen(false);
    };

    const handleUpdate = (data) => {
        let bodyData = {
            project_data: data
        }
        setProject(data);
        editProject(data.id, bodyData);
        setIsModalOpen(false);
    };

    const handleShowRemoveProject = (data) => {
        setProject(data);
        setShowRemoveConfirm(true);
        //removeProject(data.id);
    }

    const handleRemoveProject = async () => {
        // setProject(data);

        let response = await removeProject(project.id, { data: { password: inputPasswordValue } });
        if (response.status === 'Success') {
            setShowRemoveConfirm(false);
            setProject(false);
            setTextAlertRemove("");
        } else {
            setTextAlertRemove('Authentication error, please try again.');
        }

    }


    const handleEditProject = (data) => {
        handleOpenModal('edit', data);
    }

    const handleChangeInputRemove = (e) => {
        setInputPasswordValue(e.target.value);
    }


    return (
        <div>


            <ModalProject
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                title="New Project"
                onSave={handleSave}
                onCancel={handleCloseModal}
            >
                <Form
                    mode={formMode}
                    initialData={project}
                    onSubmit={formMode === 'create' ? handleSave : handleUpdate}
                    onCancel={handleCloseModal}
                />
            </ModalProject>

            <ModalConfirmRemove
                active={showRemoveConfirm}
                setValue={handleChangeInputRemove}
                remove={handleRemoveProject}
                close={handleCloseRemoveModal}
                value={inputPasswordValue}
                alert={textAlertRemove}
            />
            <div className='d-flex justify-content-between align-items-center'>
                {canCreateProject && <i class="bi bi-plus-circle-fill" style={{ fontStyle: 'normal', cursor: 'pointer', color: 'rgb(255 255 255)', backgroundColor: '#1a67a3', padding: '5px 20px', borderRadius: '5px' }} onClick={() => { handleOpenModal('create', project) }}> New Project</i>}
                <span></span>
            </div>            
            
            
            {loading ? (
                <p>Loading projects...</p>
            ) : (
                <div className="container-project mt-3 " >
                    {projects.length > 0 ? (
                        projects.map((project, index) => (
                            <div key={index} className="container-project-item" >
                                <ProjectCard
                                    {...project}
                                    selectProject={() => { handleClickProject(project) }}
                                    removeProject={() => { handleShowRemoveProject(project) }}
                                    editProject={() => { handleEditProject(project) }}
                                    subscription={subscription}
                                />
                            </div>
                        ))
                    ) : (
                        <p>You don't have any projects created yet. Start a new one</p>
                    )}
                    <div className='d-block d-md-none'>
                        {subscription.plan === 'Free' &&
                            <SubscriptionInformation userEmail={userEmail} />
                        }
                    </div>
                </div>
            )}
        </div>
    )
}

export default ProjectList