/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import querystring from "querystring";
import Loader from "../../Loader/loader.jsx";
import { BASE_URL } from "../../../Constants/serverConfig.jsx";
import { Paper } from "@mui/material";
import styles from "./ProfileInfo.module.css";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { saveServiceProvider } from "../../../Actions/serviceProvider.jsx";
import { SERVICEPROVIDER, TAB_KEY } from "../../../Constants/localStorageKeys.jsx";
import SecureLS from "secure-ls";
import ChangePassword from "../../Dashboard/components/changePassword.jsx";
import Subscription from "../../Dashboard/components/Subscription.jsx";
class IndividualInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      originalEmail: "",
      address: "",
      city: "",
      state: "",
      zipCode: "",
      vendorName: "",
      vendorLastName: "",
      vendorEmail: "",
      vendorContactNumber: "",
      profileStatus: "",
      showLoader: false,
      showError: false,
      errorMessage: "",
      showSuccess: false,
      showPasswordUpdate: false,
      showSubscriptionDetails: false,
      successMessage: "",
      sameAsAbove: false,
      confirmedEmailChange: false,
      showEmailConfirmation: false,
      individual: true,
      company: false,
      stateUserInformationPanel: false,
      stateCompanyInformationPanel: false,
      stateVendorInformationPanel: false,
      stateLocationInformationPanel: false
    };
  }

  componentDidMount() {
    const { serviceProvider } = this.props;
    if (serviceProvider !== null) {
      this.setState({
        address: serviceProvider.street_address,
        city: serviceProvider.city,
        state: serviceProvider.state,
        zipCode: serviceProvider.zip_code ? `${serviceProvider.zip_code}` : undefined,
        firstName: serviceProvider.first_name,
        lastName: serviceProvider.last_name,
        email: serviceProvider.email_address,
        originalEmail: serviceProvider.email_address,
        vendorName:
          serviceProvider.vendor_name === null
            ? ""
            : serviceProvider.vendor_name,
        vendorLastName:
          serviceProvider.vendor_last_name === null
            ? ""
            : serviceProvider.vendor_last_name,
        vendorEmail:
          serviceProvider.vendor_email === null
            ? ""
            : serviceProvider.vendor_email,
        vendorContactNumber:
          serviceProvider.vendor_contact_number === null
            ? ""
            : serviceProvider.vendor_contact_number,
        profileStatus: serviceProvider.profile_status,
      });
      if (serviceProvider.vendor_type === "Company") {
        this.setState({
          individual: false,
          company: true,
        });
      } else {
        this.setState({
          individual: true,
          company: false,
        });
      }
    }
  }

  handleChange = (event) => {
    if (event.target.name === "zipCode") {
      let zipCodeRegex = /^[0-9]{0,5}$/;
      if (zipCodeRegex.test(event.target.value)) {
        this.setState({
          [event.target.name]: event.target.value,
        });
      }
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  };

  handleFileUpload = (event) => {
    const { serviceProvider, token, saveServiceProvider } = this.props;
    if (token !== null && serviceProvider !== null) {
      this.startLoader();
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("files", file);
      axios
        .patch(`${BASE_URL}/account/profile-image`, formData, {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.stopLoader();
          if (response.status === 200) {
            this.stopLoader();
            serviceProvider.profile_image = response.data.profile_image;
            saveServiceProvider(serviceProvider);
            this.showSuccess("Profile picture updated successfully");
          }
        })
        .catch((error) => {
          this.stopLoader();
          console.error(error);
          this.showError(
            error.response !== undefined
              ? error.response.data
              : "Opps! Something went wrong please try again."
          );
        });
    }
  };

  startLoader = () => {
    this.setState({
      showLoader: true,
    });
  };

  stopLoader = () => {
    this.setState({
      showLoader: false,
    });
  };

  showError = (errorMessage) => {
    this.setState({
      showError: true,
      errorMessage,
    });
  };

  clearError = () => {
    this.setState({
      showError: false,
      errorMessage: "",
    });
  };

  showSuccess = (successMessage) => {
    this.setState({
      showSuccess: true,
      successMessage,
    });
  };

  clearSuccess = () => {
    const { successMessage } = this.state;
    if (successMessage === "Profile picture updated successfully") {
      window.location.reload();
    } else {
      this.setState({
        showSuccess: false,
        successMessage: "",
      });
    }
  };

  closePopup = () => {
    this.setState({
      showEmailConfirmation: false,
    });
  };

  validatePersonalInformation = () => {
    // let nameValidation = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
    let nameValidation = /^[a-zA-Z|Á|á|É|é|Í|í|Ó|ó|Ú|ú|Ñ|ñ]+(([',. -][a-zA-Z|Á|á|É|é|Í|í|Ó|ó|Ú|ú|Ñ|ñ])?[a-zA-Z|Á|á|É|é|Í|í|Ó|ó|Ú|ú|Ñ|ñ]*)*$/;
    let contactNumberValidation = /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\\-]?[\0-9]{3}[\\-]?[0-9]{4}$/;
    let emailValidation = /^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/;
    let zipCodeValidation = /^\d{5}$/;
    const { serviceProvider } = this.props;
    const {
      firstName,
      lastName,
      email,
      address,
      city,
      state,
      zipCode,
      vendorName,
      vendorLastName,
      vendorEmail,
      vendorContactNumber,
    } = this.state;
    if (firstName && firstName.trim() === "") {
      this.showError("Please enter name");
      return false;
    }
    if (firstName && !nameValidation.test(firstName.trim())) {
      this.showError(
        "Please enter a valid first name, first name should have 3 to 50 alphabets only"
      );
      return false;
    }
    if (lastName && lastName.trim() === "") {
      this.showError("Please enter last name");
      return false;
    }
    if (lastName && !nameValidation.test(lastName.trim())) {
      this.showError(
        "Please enter a valid last name, last name should have 3 to 50 alphabets only"
      );
      return false;
    }
    if (email && email.trim() === "") {
      this.showError("Please enter email");
      return false;
    }
    if (email && !emailValidation.test(email.trim())) {
      this.showError("Please enter a valid email");
      return false;
    }
    if (address && address.trim() === "") {
      this.showError("Please enter your address");
      return false;
    }
    if (city && city.trim() === "") {
      this.showError("Please enter city");
      return false;
    }
    if (state && state.trim() === "") {
      this.showError("Please enter state");
      return false;
    }
    if (zipCode && zipCode === "") {
      this.showError("Please enter zip code");
      return false;
    }
    if (zipCode && !zipCodeValidation.test(zipCode)) {
      this.showError("Please enter a valid zip code");
      return false;
    }
    if (!(serviceProvider.user_type === 30 || serviceProvider.user_type === 40) && vendorName.trim() === "") {
      this.showError("Please enter vendor name");
      return false;
    }
    if (!(serviceProvider.user_type === 30 || serviceProvider.user_type === 40) && !nameValidation.test(vendorName.trim())) {
      this.showError(
        "Please enter a valid vendor name, vendor name should have 3 to 50 characters"
      );
      return false;
    }
    if (!(serviceProvider.user_type === 30 || serviceProvider.user_type === 40) && vendorLastName.trim() === "") {
      this.showError("Please enter vendor last name");
      return false;
    }
    if (!(serviceProvider.user_type === 30 || serviceProvider.user_type === 40) && !nameValidation.test(vendorLastName.trim())) {
      this.showError(
        "Please enter a valid vendor last name, vendor last name should have 3 to 50 characters"
      );
      return false;
    }
    if (!(serviceProvider.user_type === 30 || serviceProvider.user_type === 40) && vendorContactNumber.trim() === "") {
      this.showError("Please enter vendor contact number");
      return false;
    }
    if (!(serviceProvider.user_type === 30 || serviceProvider.user_type === 40) && !contactNumberValidation.test(vendorContactNumber.trim())) {
      this.showError("Please enter a valid contact number");
      return false;
    }
    if (!(serviceProvider.user_type === 30 || serviceProvider.user_type === 40) && vendorEmail.trim() === "") {
      this.showError("Please enter contact email");
      return false;
    }
    if (!(serviceProvider.user_type === 30 || serviceProvider.user_type === 40) && !emailValidation.test(vendorEmail.trim())) {
      this.showError("Please enter a valid vendor email");
      return false;
    }
    this.clearError();
    return true;
  };

  confirmEmailChange = () => {
    this.setState({
      confirmedEmailChange: true,
      showEmailConfirmation: false,
    });
    this.updateProfile();
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { serviceProvider, token } = this.props;
    if (!(serviceProvider.user_type === 30 || serviceProvider.user_type === 40) && serviceProvider.profile_image[0] === null) {
      this.showError("Profile Picture is missing");
    } else {
      if (!this.validatePersonalInformation()) {
        return;
      }
      if (serviceProvider !== null && token !== null) {
        const { email, originalEmail, confirmedEmailChange } = this.state;
        if (originalEmail !== email && confirmedEmailChange === false) {
          this.setState({
            showEmailConfirmation: true,
          });
        } else {
          this.updateProfile();
        }
      }
    }
  };

  updateProfile = () => {
    const { serviceProvider, token, saveServiceProvider } = this.props;
    const {
      firstName,
      lastName,
      email,
      address,
      city,
      state,
      zipCode,
      vendorName,
      vendorLastName,
      vendorEmail,
      vendorContactNumber,
      individual,
    } = this.state;
    this.startLoader();
    const body = {
      first_name: firstName && firstName.trim(),
      last_name: lastName && lastName.trim(),
      email_address: email && email.trim(),
      username: serviceProvider.username.trim(),
      state: state && state.trim(),
      city: city && city.trim(),
      zip_code: zipCode && zipCode,
      street_address: address && address.trim(),
      vendor_name: !(serviceProvider.user_type === 30 || serviceProvider.user_type === 40) ? vendorName.trim() : '',
      vendor_last_name: !(serviceProvider.user_type === 30 || serviceProvider.user_type === 40) ? vendorLastName.trim() : '',
      vendor_email: !(serviceProvider.user_type === 30 || serviceProvider.user_type === 40) ? vendorEmail.trim() : '',
      vendor_contact_number: !(serviceProvider.user_type === 30 || serviceProvider.user_type === 40) ? vendorContactNumber.trim() : '',
    };
    if (
      (serviceProvider !== null && !(serviceProvider.user_type === 30 || serviceProvider.user_type === 40)) &&
      (serviceProvider.membership_information.membership_name.toLowerCase() ===
        "standard" ||
        serviceProvider.membership_information.membership_name.toLowerCase() ===
        "trial")
    ) {
      body.vendor_type = individual ? "Individual" : "Company";
    }

    axios
      .patch(`${BASE_URL}/account/profile`, body, {
        headers: {
          //authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        this.stopLoader();

        if (response.status === 200) {
          let updatedServiceProvider = Object.assign({}, response.data);

          if (updatedServiceProvider.membership_information) {
            updatedServiceProvider.membership_information.additional_method_remaining =
              serviceProvider.membership_information.additional_method_remaining ?? 0;
            updatedServiceProvider.membership_information.max_size_project_allowed =
              serviceProvider.membership_information.max_size_project_allowed ?? 0;
            updatedServiceProvider.membership_information.message =
              serviceProvider.membership_information.message ?? '';
          }


          updatedServiceProvider.vendor_type = individual
            ? "Individual"
            : "Company";
          this.showSuccess(
            "Your profile information has been updated successfully"
          );
          this.updateServiceProviderMembershipInfo(updatedServiceProvider);
          this.setState(
            {
              stateLocationInformationPanel: false,
              stateUserInformationPanel: false

            }

          )
        }
      })
      .catch((error) => {
        this.stopLoader();
        this.showError(
          error.response !== undefined
            ? error.response.data
            : "Opps! Something went wrong please try again."
        );
        this.setState({
          confirmedEmailChange: false,
          showEmailConfirmation: false,
        });
      });
  };

  updateServiceProviderMembershipInfo = (data) => {


    const { serviceProvider, saveServiceProvider } = this.props;
    serviceProvider.city = data.city;
    serviceProvider.code = data.code;
    serviceProvider.company_description = data.company_description;
    serviceProvider.company_name = data.company_name;
    serviceProvider.company_page = data.company_page;
    serviceProvider.contact_email = data.contact_email;
    serviceProvider.coupon = data.coupon;
    serviceProvider.email_address = data.email_address;
    serviceProvider.email_status = data.email_status;
    serviceProvider.first_name = data.first_name;
    serviceProvider.id = data.id;
    serviceProvider.last_name = data.last_name;
    serviceProvider.last_seen_at = data.last_seen_at;
    serviceProvider.profile_image = data.profile_image;
    serviceProvider.profile_status = data.profile_status;
    serviceProvider.request_membership_selection =
      data.request_membership_selection;
    serviceProvider.state = data.state;
    serviceProvider.street_address = data.street_address;
    serviceProvider.user_type = data.user_type;
    serviceProvider.username = data.username;
    serviceProvider.vendor_contact_number = data.vendor_contact_number;
    serviceProvider.vendor_email = data.vendor_email;
    serviceProvider.vendor_last_name = data.vendor_last_name;
    serviceProvider.vendor_name = data.vendor_name;
    serviceProvider.vendor_type = data.vendor_type;
    serviceProvider.zip_code = data.zip_code;

    if (serviceProvider.membership_information) {
      serviceProvider.membership_information.additional_method_remaining =
        data.membership_information.additional_method_remaining;

      serviceProvider.membership_information.hard_skill_remaining =
        data.membership_information.hard_skill_remaining;

      serviceProvider.membership_information.max_hard_skill_allowed =
        data.membership_information.max_hard_skill_allowed;

      serviceProvider.membership_information.max_method_per_hard_skill_allowed =
        data.membership_information.max_method_per_hard_skill_allowed;

      serviceProvider.membership_information.max_size_project_allowed =
        data.membership_information.max_size_project_allowed;

      serviceProvider.membership_information.max_zip_code_allowed =
        data.membership_information.max_zip_code_allowed;

      serviceProvider.membership_information.zip_code_remaining =
        data.membership_information.zip_code_remaining;

      serviceProvider.membership_information.us_metro_area_remaining =
        data.membership_information.us_metro_area_remaining;

      serviceProvider.membership_information.metro_area_selected =
        data.membership_information.metro_area_selected;
    }

    if (data.vendor_type !== undefined) {
      serviceProvider.vendor_type = data.vendor_type;
    }
    this.setState({
      membershipInfo: serviceProvider.membership_information,
    });
    if (!(serviceProvider.user_type === 30 || serviceProvider.user_type === 40)) {
      this.getServices();
    }
    try {
      saveServiceProvider(serviceProvider);

    } catch (error) {
      console.log(error);

    }
    let ls = new SecureLS();
    ls.set(SERVICEPROVIDER, serviceProvider);
  };

  setSameAsAccountOwner = (sameAsAbove) => {
    if (!sameAsAbove) {
      this.setState({
        vendorName: this.state.firstName,
        vendorLastName: this.state.lastName,
        vendorEmail: this.state.email,
        sameAsAbove: true,
      });
    } else {
      this.setState({
        vendorName: "",
        vendorLastName: "",
        vendorEmail: "",
        sameAsAbove: false,
      });
    }
  };

  handlePasswordSubmit = () => {

  }

  showError = (message) => {

  }
  showSuccess = (message) => {

  }

  closePasswordModal = () => {
    this.setState({
      showPasswordUpdate: false
    })
  }

  render() {
    const { serviceProvider, token, changeTypePanel } = this.props;
    const {
      firstName,
      lastName,
      email,
      address,
      city,
      state,
      zipCode,
      vendorName,
      vendorLastName,
      vendorEmail,
      vendorContactNumber,
      profileStatus,
      showLoader,
      showError,
      individual,
      company,
      errorMessage,
      showEmailConfirmation,
      sameAsAbove,
      showSuccess,
      successMessage,
      showPasswordUpdate,
      showSubscriptionDetails
    } = this.state;
    // console.log("serviceProvider ==>", serviceProvider);

    return (
      <div style={{ padding: "0.5rem" }}>
        <div>
          <Loader text="Loading..." open={showLoader} />
          {showError && (
            <div className="modal is-active custom-modal error-message">
              <div className="modal-background"></div>
              <div className="modal-content">
                <div className="error-message-modal">
                  <button
                    onClick={this.clearError}
                    className="modal-close"
                  ></button>
                  <p>{errorMessage}</p>
                  <a onClick={this.clearError} className="btn-fill">
                    OK
                  </a>
                </div>
              </div>
            </div>
          )}
          {showSuccess && (
            <div className="modal is-active custom-modal success-message">
              <div className="modal-background"></div>
              <div className="modal-content">
                <div className="success-message-modal">

                  <button
                    onClick={this.clearSuccess}
                    className="modal-close"
                  ></button>
                  <p>{successMessage}</p>
                  <a onClick={this.clearSuccess} className="btn-fill">
                    OK
                  </a>
                </div>
              </div>
            </div>
          )}
          {showPasswordUpdate && (
            <div className="modal is-active custom-modal" >
              <div className="modal-background"></div>

              <div className={"modal-content " + styles.modalPassword}>
                <div className="modal-header border-0 justify-content-end py-0 px-3 pt-1">
                  <span onClick={() => { this.setState({ showPasswordUpdate: false }) }} style={{ cursor: 'pointer', color: 'gray' }}>X</span>
                </div>
                <div className="px-5 pb-5">
                  <h3 className="text-center" style={{ fontFamily: "'Inter', serif" }}>Change Password</h3>
                  <ChangePassword token={token} showError={this.showError} showSuccess={this.showSuccess} closeModal={this.closePasswordModal} />
                  {/* <button
                    onClick={this.clearSuccess}
                    className="modal-close"
                  ></button> */}
                </div>
              </div>
            </div>
          )}
          {showSubscriptionDetails && (
            <div className={"modal is-active custom-modal"} >
              <div className="modal-background"></div>

              <div className={"modal-content " + styles.modalSubscription}>
                <div className="modal-header border-0 justify-content-end py-0 px-3 pt-1">
                  <span onClick={() => { this.setState({ showSubscriptionDetails: false }) }} style={{ cursor: 'pointer', color: 'gray' }}>X</span>
                </div>
                <div className="px-5 pb-5">
                  <h3 className="text-center" style={{ fontFamily: "'Inter', serif" }}>Subscription Details</h3>
                  <Subscription token={token} serviceProvider={serviceProvider} />

                  {/* <button
                    onClick={this.clearSuccess}
                    className="modal-close"
                  ></button> */}
                </div>
              </div>
            </div>
          )}
          <div
            className={`modal small-popup ${showEmailConfirmation ? "is-active" : ""
              }`}
          >
            <div className="modal-background"></div>
            <div className="modal-card ">
              <button
                onClick={this.closePopup}
                className="delete"
                aria-label="close"
              ></button>
              <section className="modal-card-body home-modal">
                <div className="endos_panel2 clearfix">
                  <h1>Are you sure to change your email?</h1>
                  <p>
                    A notification will be sent to <strong> {email}</strong>,
                    please click on the link provided to confirm this update.
                  </p>
                  <div className="map-buton">
                    <button
                      onClick={this.confirmEmailChange}
                      className="btn-fill"
                    >
                      Yes
                    </button>
                    <button onClick={this.closePopup} className="btn-empty">
                      No
                    </button>
                  </div>
                </div>
              </section>
            </div>
          </div>
          {profileStatus === "incomplete" && (
            <React.Fragment>
              <div className="notification is-warning text">
                Your personal information is incomplete, please update your
                profile.
              </div>
              <div className="clearfix"></div>
              <div className="clearfix"></div>
              <br />
            </React.Fragment>
          )}

          <div className={styles.profileInformationPanel}>
            <div data="profile" className={styles.itemsProfileInformationPanel + " " + (this.state.stateUserInformationPanel ? styles.openEdit : styles.closeEdit)}>
              <div className={styles.informationTitle}>
                <span>User Information</span>
                <i onClick={() => this.setState({
                  stateUserInformationPanel: !this.state.stateUserInformationPanel
                })}>Edit</i>
              </div>
              {
                !this.state.stateUserInformationPanel ?
                  <>
                    <div className={styles.fields}>
                      <span>Name</span>
                      <span>{this.state.firstName + " " + this.state.lastName}</span>
                    </div>
                    <div className={styles.fields}>
                      <span>Email</span>
                      <span>{this.state.email}</span>
                    </div>
                    <div className={styles.fields}>
                      <span>Type of provider</span>
                      <span>{company ? "Company" : "Individual"}</span>
                    </div>
                    <div className={styles.fields}>
                      <span>Subscription</span>
                      {/* <span>{serviceProvider.membership_information ? serviceProvider.membership_information.membership_name : 'No membership'}</span> */}
                      <span>{
                        serviceProvider.subscription ?
                          <p style={{ color: 'blue', fontStyle: 'italic', fontWeight: '400', color: 'rgb(26, 103, 163)', cursor: 'pointer', margin: '0px' }} onClick={() => { this.setState({ showSubscriptionDetails: true }) }}>{serviceProvider.subscription.plan} </p> :
                          'No subscription'}</span>
                    </div>
                    <div className={styles.fields}>
                      <span style={{ color: 'blue', fontStyle: 'italic', fontWeight: '400', color: 'rgb(26, 103, 163)', cursor: 'pointer' }} onClick={() => { this.setState({ showPasswordUpdate: true }) }}>Change Password...</span>
                      {/* <span>{serviceProvider.membership_information ? serviceProvider.membership_information.membership_name : 'No membership'}</span> */}
                      <span></span>
                    </div>
                  </>
                  :
                  <div>

                    <form onSubmit={this.handleSubmit}>
                      <div className="form-group mt-4">
                        <input type="text" className={"form-control " + styles.inputProfilePanel} id="inputName" placeholder="" name="firstName" onChange={this.handleChange} autoFocus value={this.state.firstName} />
                        <label className={styles.labelInput} htmlFor="inputName">Name</label>
                      </div>
                      <div className="form-group mt-4">
                        <input type="text" className={"form-control " + styles.inputProfilePanel} id="inputLastName" placeholder="" name="lastName" onChange={this.handleChange} value={this.state.lastName} />
                        <label className={styles.labelInput} htmlFor="inputName">Last Name</label>
                      </div>

                      {/* <div className={styles.informationTitle}>
                        <span>Email Address</span>
                      </div> */}
                      <div className="form-group">
                        <input type="text" className={"form-control " + styles.inputProfilePanel} id="inputEmail" placeholder="Email" name="email" onChange={this.handleChange} value={this.state.email} />
                        <label className={styles.labelInput} htmlFor="inputEmail">Email</label>
                      </div>
                      {/* <p className={styles.informationParaph}>We'll send you an email to confirm this change</p> */}
                      <hr></hr>
                      <div className='d-flex flex-column flex-md-row justify-content-end container-button-group mt-3'>
                        <button type="submit" className="btn btn-primary mr-2">Update</button>
                        <button type="button" className="btn btn-secondary mr-2" onClick={() => this.setState({
                          stateUserInformationPanel: !this.state.stateUserInformationPanel
                        })}>Cancel</button>
                      </div>
                    </form>


                  </div>
              }
            </div>

            {!(serviceProvider.user_type === 30 || serviceProvider.user_type === 40) &&
              < div data="vendor" className={styles.itemsProfileInformationPanel + " " + (this.state.stateVendorInformationPanel ? styles.openEdit : styles.closeEdit)}>
                <div className={styles.informationTitle}>
                  <span>Vendor Information</span>
                  <i onClick={() => this.setState({
                    stateVendorInformationPanel: !this.state.stateVendorInformationPanel
                  })}>Edit</i>
                </div>
                {
                  !this.state.stateVendorInformationPanel ?
                    <>
                      <div className={styles.fields}>
                        <span>Name</span>
                        <span>{vendorName + " " + vendorLastName}</span>
                      </div>
                      <div className={styles.fields}>
                        <span>Email</span>
                        <span>{vendorEmail}</span>
                      </div>
                      <div className={styles.fields}>
                        <span>Contact Number</span>
                        <span>{vendorContactNumber}</span>
                      </div>

                    </>
                    :
                    <div>

                      <form onSubmit={this.handleSubmit}>
                        <div className="form-group mt-4">
                          <input type="text" className={"form-control " + styles.inputProfilePanel} id="inputName" placeholder="" name="vendorFirstName" onChange={this.handleChange} autoFocus value={this.state.vendorName} />
                          <label className={styles.labelInput} htmlFor="inputName">Name</label>
                        </div>
                        <div className="form-group mt-4">
                          <input type="text" className={"form-control " + styles.inputProfilePanel} id="inputLastName" placeholder="" name="vendorLastName" onChange={this.handleChange} value={this.state.vendorLastName} />
                          <label className={styles.labelInput} htmlFor="inputName">Last Name</label>
                        </div>

                        {/* <div className={styles.informationTitle}>
                        <span>Email Address</span>
                      </div> */}
                        <div className="form-group">
                          <input type="text" className={"form-control " + styles.inputProfilePanel} id="inputEmail" placeholder="Email" name="vendorEmail" onChange={this.handleChange} value={this.state.vendorEmail} />
                          <label className={styles.labelInput} htmlFor="inputEmail">Email</label>
                        </div>

                        <div className="form-group mt-4">
                          <input type="text" className={"form-control " + styles.inputProfilePanel} id="inputContact" placeholder="" name="vendorContactNumber" onChange={this.handleChange} value={this.state.vendorContactNumber} />
                          <label className={styles.labelInput} htmlFor="inputContact">Contact number</label>
                        </div>
                        {/* <p className={styles.informationParaph}>We'll send you an email to confirm this change</p> */}
                        <hr></hr>
                        <div className={styles.divActionsButtons}>
                          <button type="submit" className="btn btn-primary btn-submit">Update</button>
                          <button type="button" className="btn btn-primary btn-cancel" onClick={() => this.setState({
                            stateVendorInformationPanel: !this.state.stateVendorInformationPanel
                          })}>Cancel</button>
                        </div>
                      </form>


                    </div>
                }
              </div>
            }

            {company &&
              <div data="company" className={styles.itemsProfileInformationPanel + " " + (this.state.stateCompanyInformationPanel ? styles.openEdit : styles.closeEdit)}>
                <div className={styles.informationTitle}>
                  <span>Company Information</span>
                  <i onClick={() => this.setState({
                    stateCompanyInformationPanel: !this.state.stateCompanyInformationPanel
                  })}>Edit</i>
                </div>
                {
                  !this.state.stateCompanyInformationPanel ?
                    <>
                      <div className={styles.fields}>
                        <span>Name</span>
                        <span>{this.state.companyName}</span>
                      </div>
                      <div className={styles.fields}>
                        <span>Website</span>
                        <span>{this.state.companyWebsite}</span>
                      </div>
                      {/* <div className={styles.fields}>
                      <span>Address</span>
                      <span>{this.state.address}</span>
                    </div> */}
                    </>
                    :
                    <div>

                      <form onSubmit={this.handleSubmit}>
                        <div className="form-group mt-4">
                          <input type="text" className={"form-control " + styles.inputProfilePanel} id="inputName" placeholder="" name="companyName" onChange={this.handleChange} autoFocus value={this.state.companyName} />
                          <label className={styles.labelInput} htmlFor="inputName">Company Name</label>
                        </div>
                        <div className="form-group mt-4">
                          <input type="text" className={"form-control " + styles.inputProfilePanel} id="inputSite" placeholder="" name="companyWebsite" onChange={this.handleChange} value={this.state.companyWebsite} />
                          <label className={styles.labelInput} htmlFor="inputSite">Web site</label>
                        </div>
                        {/* <div className="form-group">
                        <input type="text" className={"form-control " + styles.inputProfilePanel} id="inputAddress" placeholder="Address" value={this.state.address} />
                      </div> */}
                        <hr></hr>
                        <div className={styles.divActionsButtons}>
                          <button type="submit" className="btn btn-primary btn-submit">Update</button>
                          <button type="button" className="btn btn-primary btn-cancel" onClick={() => this.setState({
                            stateCompanyInformationPanel: !this.state.stateCompanyInformationPanel
                          })}>Cancel</button>
                        </div>
                      </form>


                    </div>
                }
              </div>
            }

            <div data="location" className={styles.itemsProfileInformationPanel + " " + (this.state.stateLocationInformationPanel ? styles.openEdit : styles.closeEdit)}>
              <div className={styles.informationTitle}>
                <span>Location Information</span>
                <i onClick={() => this.setState({
                  stateLocationInformationPanel: !this.state.stateLocationInformationPanel
                })}>Edit</i>
              </div>
              {
                !this.state.stateLocationInformationPanel ?
                  <>
                    <div className={styles.fields}>
                      <span>Address</span>
                      <span>{this.state.address}</span>
                    </div>
                    <div className={styles.fields}>
                      <span>State</span>
                      <span>{this.state.state}</span>
                    </div>
                    <div className={styles.fields}>
                      <span>City</span>
                      <span>{this.state.city}</span>
                    </div>
                    <div className={styles.fields}>
                      <span>Zip Code</span>
                      <span>{this.state.zipCode ?? ''}</span>
                    </div>
                  </>
                  :
                  <div >

                    <form onSubmit={this.handleSubmit}>
                      <div className="form-group mt-4">
                        <input type="text" className={"form-control " + styles.inputProfilePanel} id="inputAddress" placeholder="" autoFocus name="address" onChange={this.handleChange} value={this.state.address} />
                        <label className={styles.labelInput} htmlFor="inputAddress">Address</label>
                      </div>
                      <div className="form-group mt-4">
                        <input type="text" className={"form-control " + styles.inputProfilePanel} id="inputState" placeholder="" name="state" onChange={this.handleChange} value={this.state.state} />
                        <label className={styles.labelInput} htmlFor="inputState">State</label>
                      </div>
                      <div className="form-group mt-4">
                        <input type="text" className={"form-control " + styles.inputProfilePanel} id="inputCity" placeholder="" name="city" onChange={this.handleChange} value={this.state.city} />
                        <label className={styles.labelInput} htmlFor="inputCity">City</label>
                      </div>
                      <div className="form-group mt-4">
                        <input type="text" className={"form-control " + styles.inputProfilePanel} id="inputZip" placeholder="" name="zipCode" onChange={this.handleChange} value={this.state.zipCode} />
                        <label className={styles.labelInput} htmlFor="inputZip">Zip Code</label>
                      </div>
                      {/* <div className="form-group">
                        <input type="text" className={"form-control " + styles.inputProfilePanel} id="inputAddress" placeholder="Address" value={this.state.address} />
                      </div> */}
                      <hr></hr>
                      <div className='d-flex flex-column flex-md-row justify-content-end container-button-group mt-3'>
                        <button type="submit" className="btn btn-primary mr-2">Update</button>
                        <button type="button" className="btn btn-secondary mr-2" onClick={() => this.setState({
                          stateLocationInformationPanel: !this.state.stateLocationInformationPanel
                        })}>Cancel</button>
                      </div>
                    </form>


                  </div>
              }
            </div>
          </div>

          <form className="d-none" onSubmit={this.handleSubmit}>
            <div className="pro-info clearfix">
              <div className="pro-pic visible-767">
                <div className="dashboard-pic is-280x280">
                  <figure
                    style={{
                      backgroundImage: `url(${serviceProvider !== null
                        ? serviceProvider.profile_image && serviceProvider.profile_image[0]
                        : ""
                        })`,
                    }}
                  ></figure>
                  <div className="image">
                    <div className="pic-btn">
                      <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id="profile-picture-mobile"
                        name="profile-picture"
                        type="file"
                        onChange={this.handleFileUpload}
                      />
                      <label htmlFor="profile-picture-mobile">
                        <i className="fa fa-upload" aria-hidden="true"></i>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="row">
                {!!company && (
                  <>
                    <div className="form-group divide-2">
                      <label>Company Name</label>
                      <input
                        type="text"
                        value={serviceProvider.company_name}
                        name="firstName"
                        onChange={this.handleChange}
                        required
                        className="form-control"
                      />
                    </div>
                    <div className="form-group divide-2">
                      <label>Company Website</label>
                      <input
                        type="text"
                        value={serviceProvider.company_page}
                        name="firstName"
                        onChange={this.handleChange}
                        required
                        className="form-control"
                      />
                    </div>
                  </>
                )}
                <div className="form-group divide-2">
                  <label>Account Owner Name</label>
                  <input
                    type="text"
                    value={firstName}
                    name="firstName"
                    onChange={this.handleChange}
                    required
                    className="form-control"
                  />
                </div>
                <div className="form-group divide-2">
                  <label>Account Owner Last Name</label>
                  <input
                    type="text"
                    value={lastName}
                    name="lastName"
                    onChange={this.handleChange}
                    required
                    className="form-control"
                  />
                </div>
                <div className="form-group divide-2">
                  <label>Account Owner Email</label>
                  <input
                    type="email"
                    value={email}
                    name="email"
                    onChange={this.handleChange}
                    required
                    className="form-control"
                  />
                </div>
                <div className="form-group divide-2">
                  <label>Membership</label>
                  <div className="columns" style={{ marginLeft: 0 }}>
                    <div className="column is-6" style={{ padding: 0 }}>
                      <input
                        type="text"
                        className="form-control"
                        disabled="disabled"
                        value={
                          serviceProvider.membership_information
                            ? serviceProvider.membership_information
                              .membership_name !== undefined
                              ? serviceProvider.membership_information
                                .membership_name
                              : "You do not have a membership account"
                            : ""
                        }
                        name="membership"
                      />
                    </div>
                    <div className="column is-6" style={{ padding: 0 }}>
                      <button
                        className="pf-submit"
                        type="button"
                        // disabled={
                        //   serviceProvider.membership_information.membership_id ===
                        //   3
                        // }
                        onClick={() => {
                          this.props.history.push(
                            "/vendor/subscription-upgrade"
                          );
                        }}
                        style={{
                          margin: 0,
                          height: 46,
                          width: "100%",
                          backgroundColor:
                            serviceProvider.membership_information && serviceProvider.membership_information
                              .membership_id === 3
                              ? "rgba(26, 103, 163, 0.7)" /* "rgba(54, 51, 119, 0.7)" */
                              : "rgb(26, 103, 163)" /* "rgb(54, 51, 119)" */,
                        }}
                      >
                        Upgrade
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="form-group">
                <label>Company/Vendor Address</label>
                <input
                  type="text"
                  value={address}
                  name="address"
                  onChange={this.handleChange}
                  required
                  className="form-control"
                />
              </div>
            </div>
            <div className="pro-pic hidden-767">
              <div className="dashboard-pic is-280x280">
                <figure
                  style={{
                    backgroundImage: `url(${serviceProvider.profile_image
                      ? serviceProvider.profile_image[0]
                      : ""
                      })`,
                  }}
                ></figure>
                <div className="image">
                  <div className="pic-btn">
                    <input
                      accept="image/*"
                      style={{ display: "none" }}
                      id="profile-picture-desktop"
                      name="profile-picture"
                      type="file"
                      onChange={this.handleFileUpload}
                    />
                    <label htmlFor="profile-picture-desktop">
                      <i className="fa fa-upload" aria-hidden="true"></i>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="new-row clearfix">
              <div className="form-group divide-3">
                <label>City</label>
                <input
                  type="text"
                  value={city}
                  name="city"
                  onChange={this.handleChange}
                  required
                  className="form-control"
                />
              </div>
              <div className="form-group divide-3">
                <label>State</label>
                <input
                  type="text"
                  value={state}
                  name="state"
                  onChange={this.handleChange}
                  required
                  className="form-control"
                />
              </div>
              <div className="form-group divide-3">
                <label>Zip Code</label>
                <input
                  type="text"
                  value={zipCode}
                  name="zipCode"
                  onChange={this.handleChange}
                  required
                  className="form-control"
                />
              </div>
            </div>
            {serviceProvider !== null &&
              (serviceProvider.membership_information && serviceProvider.membership_information.membership_name.toLowerCase() ===
                "standard" ||
                serviceProvider.membership_information && serviceProvider.membership_information.membership_name.toLowerCase() ===
                "trial") && (
                <React.Fragment>
                  <div className="clearfix"></div>
                  <div className="new-row clearfix">
                    <div className="form-group divide-3">
                      <label>Type of provider</label>
                      <div className="columns">
                        <div className="column is-6">
                          <div
                            onClick={() => {
                              this.setState({
                                individual: !individual,
                                company: false,
                              });
                            }}
                            className="checkbox"
                          >
                            <label
                              onClick={(event) => {
                                event.preventDefault();
                              }}
                            >
                              <input
                                checked={individual}
                                onChange={() => { }}
                                type="checkbox"
                                onClick={(event) => {
                                  event.preventDefault();
                                }}
                              />
                              <span className="checkmark"></span>Individual
                            </label>
                          </div>
                        </div>
                        <div className="column is-6">
                          <div
                            onClick={() => {
                              this.setState({
                                individual: false,
                                company: !company,
                              });
                            }}
                            className="checkbox"
                          >
                            <label
                              onClick={(event) => {
                                event.preventDefault();
                              }}
                            >
                              <input
                                checked={company}
                                onChange={() => { }}
                                type="checkbox"
                                onClick={(event) => {
                                  event.preventDefault();
                                }}
                              />
                              <span className="checkmark"></span>Company
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
            <div className="clearfix"></div>
            <div className="new-row clearfix">
              <div className="form-group divide-3">
                <p className="title is-4" style={{ color: "#4a4a4a" }}>
                  Vendor Details
                </p>
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="new-row clearfix">
              <div className="form-group divide-3">
                <div
                  onClick={() => this.setSameAsAccountOwner(sameAsAbove)}
                  className="checkbox"
                >
                  <label
                    onClick={(event) => {
                      event.preventDefault();
                    }}
                  >
                    <input
                      onChange={() => { }}
                      onClick={(event) => {
                        event.preventDefault();
                      }}
                      checked={sameAsAbove}
                      type="checkbox"
                    />
                    <span className="checkmark"></span>Same as account owner
                  </label>
                </div>
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="new-row clearfix">
              <div className="form-group divide-3">
                <label>Vendor Name</label>
                <input
                  type="text"
                  value={vendorName}
                  name="vendorName"
                  onChange={this.handleChange}
                  required
                  className="form-control"
                />
              </div>
              <div className="form-group divide-3">
                <label>Vendor Last Name</label>
                <input
                  type="text"
                  value={vendorLastName}
                  name="vendorLastName"
                  onChange={this.handleChange}
                  required
                  className="form-control"
                />
              </div>
              <div className="form-group divide-3">
                <label>Vendor Contact Number</label>
                <input
                  type="text"
                  value={vendorContactNumber}
                  name="vendorContactNumber"
                  onChange={this.handleChange}
                  required
                  className="form-control"
                />
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="new-row clearfix">
              <div className="form-group divide-3">
                <label>Vendor Email</label>
                <input
                  type="email"
                  value={vendorEmail}
                  name="vendorEmail"
                  onChange={this.handleChange}
                  required
                  className="form-control"
                />
              </div>
            </div>
            <div className="prof-button" /* style={{ marginBottom: "30px" }} */>
              <button className="pf-submit" type="submit">
                Submit
              </button>
            </div>
          </form>
          <div className="clearfix"></div>
          <div className={`d-flex d-md-none flex-row justify-content-between ${styles.itemsProfileInformationPanelOptionsNotification}`} onClick={()=>changeTypePanel('Notifications')}>
            <span>Notifications</span>
            <span className="text-light px-2" style={{backgroundColor:'#1a67a3'}}>0</span>
          </div>
          <div className={`d-flex d-md-none flex-row justify-content-between ${styles.itemsProfileInformationPanelOptionsLogout}`} onClick={()=>changeTypePanel('Logout')}>
            <span>Logout</span>
            <span className="bg-danger text-light px-1"><i class="bi bi-box-arrow-right"></i></span>
          </div>
        </div>
      </div >
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      saveServiceProvider,
    },
    dispatch
  );
};

export default compose(
  withRouter,
  connect(null, mapDispatchToProps)
)(IndividualInformation);
