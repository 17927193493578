import React, { useState, useEffect } from 'react'
import useSubscriptions from '../../hooks/useSubscriptions';
import useSubscriptionWatcher from '../../hooks/useSubscriptionWatcher';
import SecureLS from "secure-ls";
import { useSelector, useDispatch } from "react-redux";
import { saveServiceProvider } from '../../Actions/serviceProvider';
import { SERVICEPROVIDER } from '../../Constants/localStorageKeys';
import { showGlobalMessage, updateGlobalMessage } from '../../Actions/messageAlert';

const SubscriptionInformation = ({ userEmail, upgrade = false }) => {

    const token = useSelector((state) => state.token.token);
    const dispatch = useDispatch();
    const [paymentProgress, setPaymentProgress] = useState(false);
    const { subscription, getSubscription } = useSubscriptions(token);

    useSubscriptionWatcher({
        paymentProgress,
        getSubscription: getSubscription,
        findCont: false
    });

    useEffect(() => {
        if (subscription?.status === "active" && paymentProgress && subscription) {
            let ls = new SecureLS();
            let user = ls.get(SERVICEPROVIDER);
            user.subscription = subscription;
            dispatch(saveServiceProvider(user));
            dispatch(showGlobalMessage(true));
            dispatch(updateGlobalMessage("You're all set! Your payment went through, and you can now enjoy all the perks of your Basic membership."));
            ls.set(SERVICEPROVIDER, user);
            setPaymentProgress(false);
        }
    }, [subscription])

    const handleActivePaymentProgress = () => {

        setPaymentProgress(true);
        window.open('https://buy.stripe.com/test_7sIdTocj02dU0qkeUZ?prefilled_email=' + userEmail, '_blank');
    }

    return (
        <div className='text-center' style={{ marginTop: '8vh', padding: '15px', backgroundColor: '#e7e7e7', borderRadius: '5px' }}>
            <p style={{ fontSize: '15px', fontWeight: '700', fontFamily: 'Inter, serif' }}>
                Unlock  Cloud Editing <br></br>& Beta Tools
            </p>
            <p style={{ fontSize: '15px', fontWeight: '500', fontFamily: 'Inter, serif' }}>
                Edit your framework's table of content in the cloud, and access exclusive beta features.
            </p>
            <p style={{ fontSize: '15px', fontWeight: '500' }}>
                Basic plan is only $24.95/Mo
            </p>
            <button
                className='w-100'
                style={{ backgroundColor: '#fcc75c', padding: '5px', borderRadius: '5px', border: '0px', color: "#d2691e", fontWeight: '800', fontSize: '18px' }}
                onClick={() => { handleActivePaymentProgress() }}
            >Join Basic</button>
        </div>
    )
}

export default SubscriptionInformation