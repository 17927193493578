import React, { useState, useRef } from 'react';
import { Overlay, OverlayTrigger, Tooltip } from 'react-bootstrap';

const SectionItem = ({ id, label, onClick, active, isSelectedProject, subscription, handleShowSubscriptionsWall }) => {

  const target = useRef(null);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      You should select a project first
    </Tooltip>
  );

  const handleLabel = () => {

    if (isSelectedProject) {
      if ((subscription === 'no-plan' && label === 'Feasibility Phase') || (subscription === 'no-plan' && label === 'Work Breakdown Structure')) {
        onClick(label);
      }
      else if (subscription === 'no-plan' && label !== 'Feasibility Phase') {
        handleShowSubscriptionsWall();
      } else {
        onClick(label);
      }
    }
  }




  return (
    <>
      <div
        ref={target}
        onClick={handleLabel}
        className={`p-2 mb-2 cursor-pointer tour-menu-${id}`}
        style={{
          borderRadius: '0px',
          cursor: "pointer",
          borderLeft: active && isSelectedProject ? '6px solid #1a67a380' : '0px',

        }}
      >
        {isSelectedProject ?
          <span  style={{
            color: isSelectedProject && !active ? '#1a67a3' : active && isSelectedProject ? 'rgb(252, 199, 92)' : 'gray',
            fontWeight: '500',
            fontFamily: 'Inter, serif',
            letterSpacing:'.5px'
          }}>{label}&nbsp;</span>
          :
          <OverlayTrigger
            target={target.current}
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
          >
            <span  style={{
              color: isSelectedProject ? '#1a67a3' : active && isSelectedProject ? '#1a67a3' : 'gray',
              fontWeight: '500',
              fontFamily: 'Inter, serif',
              letterSpacing:'.5px'
            }}>{label}&nbsp;</span>

          </OverlayTrigger>
        }

      </div>

    </>
  );
}

export default SectionItem;
