import { useEffect, useRef } from 'react';

const useSubscriptionWatcher = ({ paymentProgress, getSubscription, findCont=true }) => {
  const intervalRef = useRef(null);
  useEffect(() => {
    if (paymentProgress) {
      intervalRef.current = setInterval(async () => {
        await getSubscription();
        const blockerExists = document.querySelector('.modal-subscription-alert');
        if (!blockerExists && findCont) {
          window.location.href = '/vendor/dashboard';
        }
      }, 3000);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [paymentProgress, getSubscription]);
};

export default useSubscriptionWatcher;
