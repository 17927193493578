import React, { useState, useEffect, useRef } from 'react';
import CollapsibleList from './CollapsibleList';
import CollapsibleListView from './CollapsibleListView';
import 'bootstrap/dist/css/bootstrap.min.css';
import { resolveTree } from '../../../Helpers/CheckTreeHelper';


function InteractivePanel({ propsData, close, save, saveNote, getNote, download, type, format = 'framework', setIsNoteActive }) {

  const [path, setPath] = useState([]);
  const [data, setData] = useState();
  const [currentNoteId, setCurrentNoteId] = useState("");
  const [currentNoteName, setCurrentNoteName] = useState("");
  const [currentNoteMobileValue, setCurrentNoteMobileValue] = useState("");

  const [seconds, setSeconds] = useState(0); // Contador de segundos
  const [lastCompletedTime, setLastCompletedTime] = useState(null); // Última vez que se cumplió 60 segundos

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prev) => {
        if (prev === 3599) {
          const now = new Date();
          setLastCompletedTime(now); // Guardar la hora actual cuando se cumplen los 60 segundos
          handleAutoSave();
          return 0; // Reiniciar contador
        }
        return prev + 1;
      });
    }, 1000); // Incrementar cada segundo

    return () => clearInterval(interval); // Limpiar intervalo al desmontar
  }, []);

  // Calcular hace cuántos minutos fue la última vez
  const timeAgo = lastCompletedTime
    ? Math.floor((new Date().getTime() - lastCompletedTime.getTime()) / 60000)
    : null;


  const collapsibleRef = useRef(null);

  useEffect(() => {
    if (path.length) setData(resolveTree(path, data));
  }, [])

  useEffect(() => {
    setData(propsData)
  }, [propsData])

  const handleSave = () => {
    if (collapsibleRef.current) {
      const selectedItems = collapsibleRef.current.saveCheckedItems();
      save(selectedItems, false);
      //console.log('Selected Items:', selectedItems);
    }
  };
  const handleAutoSave = (props = {}) => {
    if (collapsibleRef.current) {
      const selectedItems = collapsibleRef.current.saveCheckedItems();
      save(selectedItems, true, props);
      //console.log('Selected Items:', selectedItems);
    }
  };

  const handleSaveNote = (currentNoteId, note) => {
    handleAutoSave({ currentNoteId: currentNoteId, note: note });
    saveNote(currentNoteId, note);
  }
  const handleGetNote = async (currentNoteId) => {
    let note = await getNote(currentNoteId);
    setCurrentNoteMobileValue(note)

  }
  const handleChangeNoteMobile = (e) => {
    setCurrentNoteMobileValue(e.target.value);
  }

  return (
    <div className='cont-modal-framework' >
      {/* OffCanva for notes */}
      <div className={`overlay-offCanvas ${currentNoteId ? 'active' : ''}`}>
        <div className={`offCanvas ${currentNoteId ? 'active' : 'hidden'}`}>
          <div className='d-flex flex-row justify-content-end'>
            <small style={{color:'#b91e1e', cursor:'pointer'}} onClick={() => { setCurrentNoteId("") }}>Close X</small>
          </div>
          <h6 className='mt-2'>Add note for {currentNoteName}</h6>
          <textarea className='mt-2' value={currentNoteMobileValue} onChange={(e) => { handleChangeNoteMobile(e) }}></textarea>
          <div className='d-flex flex-column flex-md-row justify-content-center container-button-group mt-3'>
            <button className='btn btn-primary' onClick={() => { handleSaveNote(currentNoteId, currentNoteMobileValue) }} style={{ width: '100%' }}>Save Note</button>
          </div>
        </div>
      </div>

      <div className='d-flex flex-column flex-md-row justify-content-between pl-md-4 cont-button-group pt-0 pt-md-3'>
        {lastCompletedTime &&
          <div className='d-flex flex-row justify-content-start w-100 align-items-center cont-autosave'>
            <i class="bi bi-floppy-fill" style={{ fontSize: '14px', color: 'gray' }}> </i>
            <span className="d-flex flex-row justify-content-start w-100 align-items-center" style={{ fontSize: '14px', margin: '0px', color: 'gray', fontStyle: 'italic' }}>&nbsp;&nbsp;Auto Save - {timeAgo} min ago</span>
          </div>
        }

        <div className={'d-flex flex-row justify-content-center justify-content-md-end w-100 align-items-center px-0 px-md-4 container-button-group'}>
          {type === 'edit' && <button className={'btn btn-primary mr-2 ' + (currentNoteId && 'd-none d-md-block')} onClick={handleSave}>Save Framework</button>}
          {(type === 'show' && format === 'framework') && <button className="btn btn-primary mr-2" style={{ width: 'auto' }} onClick={() => { download(data.id) }}>Download Framework</button>}
          {(type === 'show' && format === 'wbs') && <button className="btn btn-primary mr-2" style={{ width: 'auto' }} onClick={() => { download(data.id) }}>Download Table of Content</button>}
          <button className={'btn btn-secondary mr-2 ' + (currentNoteId && 'd-none d-md-block')} onClick={close}>Close</button>
        </div>

      </div>
      <div className={" body-framework-items "}>
        {data && type === 'edit' && <CollapsibleList
          ref={collapsibleRef}
          items={data.children}
          padding={0}
          currentNote={currentNoteId}
          setCurrentNote={setCurrentNoteId}
          setCurrentNoteName={setCurrentNoteName}
          saveNote={handleSaveNote}
          getNote={getNote}
          setNoteMobile={setCurrentNoteMobileValue}
        />}
        {data && type === 'show' && <CollapsibleListView items={data.children ?? []} padding={0} format={format} />}
      </div>
      {/* {currentNoteId &&
        <div className="text-area-notes" style={{

        }}>
          <span>Write a note for {currentNoteName}</span>
          <textarea rows={8} value={currentNoteMobileValue} onChange={(e) => { handleChangeNoteMobile(e) }}></textarea>
          <div className='d-flex flex-row mt-4'>
            <button className='btn-note-save w-100' onClick={() => { handleSaveNote(currentNoteId, currentNoteMobileValue) }}>Save</button>
            <button className='btn-note-close w-100' onClick={() => { setCurrentNoteId("") }}>Close</button>
          </div>
        </div>
      } */}

    </div>
  );
}

export default InteractivePanel;
