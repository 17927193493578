import React from 'react';
import SectionItem from '../Atoms/SectionItem';
import SubscriptionInformation from './SubscriptionInformation';
import InviteToSignup from './InviteToSignup';
import SectionItemAccount from '../Atoms/SectionItemAccount';

const SectionMenu = ({ items, selectedPhase, isSelectedProject, subscription, userEmail, handleShowSubscriptionsWall, typePage }) => {

  return (
    <div className="section-menu">
      {typePage === 'Projects' && items.map((item, index) => (
        <SectionItem
          id={item.id}
          label={item.label}
          onClick={item.onClick}
          active={item.label === selectedPhase}
          isSelectedProject={isSelectedProject}
          subscription={subscription.plan}
          handleShowSubscriptionsWall={handleShowSubscriptionsWall}
        />
      ))}
      {typePage === 'Account' && items.map((item, index) => (
        <SectionItemAccount
          id={item.id}
          label={item.label}
          onClick={item.onClick}
          active={item.label === selectedPhase}
        />
      ))}

      {typePage === 'Dashboard' && items.map((item, index) => (
        <SectionItemAccount
          id={item.id}
          label={item.label}
          onClick={item.onClick}
          active={false}
        />
      ))}

      {
        subscription.plan === "Free" &&
        <SubscriptionInformation userEmail={userEmail} />
      }
      {
        subscription.plan === "no-plan" &&
        <InviteToSignup handleShowSubscriptionsWall={handleShowSubscriptionsWall} />
      }

    </div>
  )
};

export default SectionMenu;
