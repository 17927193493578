import React from 'react';
import { Modal } from 'react-bootstrap';


const ModalSubscriptionAlert = ({ show, handleClose, stripeUrl='', handleActivePaymentProgress }) => {
    if (!show) return null;

    const goToStripe = ()=>{
        handleActivePaymentProgress();
        window.open(stripeUrl, '_blank');
    }

    return (
        <div>
            <Modal className="modal-subscription-alert" show={show} onHide={handleClose} size="lg" centered>
                
                <Modal.Body className='d-flex flex-column justify-content-center align-items-center'>
                    <img src="../images/warningAlert.png" width={200} />
                    <h3 style={{fontSize:'50px'}}>Oops!</h3>
                    <p className='text-center' style={{fontSize:'18px'}}>
                        We encountered an issue renewing your subscription. Kindly update your payment method to ensure uninterrupted access.
                    </p>
                    <div className='container-button-update-payment w-100 text-center mt-2'>
                        <button className='btn btn-primary' style={{fontSize:'18px'}} onClick={goToStripe}>
                            Update Payment Method
                        </button>
                    </div>
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>

        </div>
    );
};

export default ModalSubscriptionAlert;
