import React,{useEffect} from 'react';
import SectionMenu from '../Molecules/SectionMenu';

const LeftSection = ({ menuItems, selectedPhase, isSelectedProject, subscription = { plan: 'no-plan' }, userEmail = '', handleShowSubscriptionsWall, typePage = 'Projects' }) => {

  
  
  return (
    <div
      className="left-section bg-light px-4 py-5"
      style={{
        width: '100%',      
         height: 'calc(100vh - 56px)', // Resta la altura del header (56px en Bootstrap)
        overflowY: 'auto',
        // height:'100%'
      }}
    >
      <h5 className='d-none d-md-block' style={{ padding: "2px 0px", backgroundColor: "#e7e7e7", textAlign: 'center', fontFamily: 'Inter, serif', fontSize: '16px', color: '#5e5e5e', letterSpacing: '.5px' }}>{typePage === 'Account' ? 'Account' : typePage === 'Dashboard'? 'Home':'Categories'}</h5>
      <SectionMenu
        items={menuItems}
        selectedPhase={selectedPhase}
        isSelectedProject={isSelectedProject}
        subscription={subscription}
        userEmail={userEmail}
        handleShowSubscriptionsWall={handleShowSubscriptionsWall}
        typePage={typePage}
      />
    </div>
  )
};

export default LeftSection;
