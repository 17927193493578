import React from 'react';

const NotificationCard = ({ id, name, description, subscription = [] }) => {


    return (
        <div className="card mb-3" style={{ maxWidth: '100%', cursor: 'pointer' }}>
            <div className="card-body p-0" style={{ borderRadius: '3px', }}>

                <p className="card-text" style={{ padding: '5px 15px' }}>
                    <div className='d-flex flex-row justify-content-between px-2'><span style={{ fontSize: '16px', fontWeight: '500', marginTop: '2px' }}>{description}</span></div>
                </p>
            </div>
        </div>
    );
};

export default NotificationCard;
