import React from 'react';
import { Modal } from 'react-bootstrap';


const ModalGlobalNotification = ({ show, close, message }) => {
    if (!show) return null;

    const closeModal = () => {
        close();
    }

    return (
        <div>
            <Modal className="modal-subscription-alert" show={show} onHide={closeModal} size="lg" centered>

                <Modal.Body className='d-flex flex-column justify-content-center align-items-center'>
                    <img src="../images/complete.png" width={250} />
                    <h3 style={{ fontSize: '50px' }}>All Set!</h3>
                    <p className='text-center' style={{ fontSize: '18px' }}>
                        { message }
                    </p>
                    <div className='container-button-update-payment w-100 text-center mt-2'>
                        <button className='btn btn-primary' style={{ fontSize: '18px' }} onClick={closeModal}>
                            Continue
                        </button>
                    </div>
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>

        </div>
    );
};

export default ModalGlobalNotification;
