import React from 'react'
import PageLayout from '../Templates/PageLayout'

const MainPage = ({logout, updateSubscription}) => {
    return (
        <>
            <PageLayout logout={logout} updateSubscription={updateSubscription}></PageLayout>
        </>
    )
}

export default MainPage