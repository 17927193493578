import React, { useState } from 'react';
import { Overlay, OverlayTrigger, Tooltip } from 'react-bootstrap';

const SectionItemAccount = ({ id, label, onClick, active }) => {

  return (
    <>
      <div
        onClick={onClick}
        className={`p-2 mb-2 cursor-pointer`}
        style={{
          borderRadius: '0px',
          cursor: "pointer",
          borderLeft: active ? '6px solid #1a67a380' : '0px',

        }}
      >
       
          <span  style={{
            color: active ? 'rgb(252, 199, 92)' : 'gray',
            fontWeight: '500',
            fontFamily: 'Inter, serif',
            letterSpacing:'.5px'
          }}>{label}&nbsp;</span>
          
        

      </div>

    </>
  );
}

export default SectionItemAccount;
