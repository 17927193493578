import React from 'react'
import FrameworkCard from '../../Atoms/FrameworkCard'

function FrameworkList({frameworks,loading, selectFramework,removeFramework,updateFramework,showTableContent, selectedTab, downloadFramework,stopTour = null}) {
  return (
    <div className='mt-5'>
            {loading ? (
                <p>Loading frameworks...</p>
            ) : (
                <div className="container-frameworks" >
                    {frameworks.length > 0 ? (
                        frameworks.map((framework, index) => (
                            <div key={index} className="container-frameworks-item">
                                <FrameworkCard
                                    {...framework}
                                    selectFramework={selectFramework}
                                    removeFramework={removeFramework}
                                    updateFramework={updateFramework}
                                    showTableContent={showTableContent}
                                    downloadFramework={downloadFramework}
                                    selectedTab={selectedTab} 
                                    stopTour={stopTour}                                  
                                />
                            </div>
                        ))
                    ) : (
                        <p>Frameworks not available.</p>
                    )}
                </div>
            )}
        </div>
  )
}

export default FrameworkList