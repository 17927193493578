import React from 'react';

const ProjectCard = ({ id, name, zip_code, gross_area, work_type, main_building_use, selectProject=null, removeProject=null, editProject=null, subscription=null, editable=true }) => {

    const handleSelectProject = () => {
        selectProject();
    }

    const handleRemoveProject = (e) => {
        e.stopPropagation();
        removeProject();
    }
    const handleEditProject = (e) => {
        e.stopPropagation();
        editProject();
    }

    return (
        <div className="card mb-3 tour-search" style={{ maxWidth: '100%', cursor: 'pointer' }}>
            <div className="card-body p-0" style={{borderRadius: '3px', }} onClick={()=>{editable&&handleSelectProject()}}>
                <div className='d-flex flex-row justify-content-between' style={{ backgroundColor: '#e7e7e7', padding: '5px 15px' }}>
                    <h5 className="card-title m-0" style={{ fontSize: '16px', fontWeight: '600', fontFamily: 'Gotham, sans-serif', color: '#626262', textTransform: 'capitalize' }}>{name?.toLowerCase()}</h5>
                    <div>
                        {(subscription?.plan !== 'no-plan' && editable) &&
                            <>
                                <i className="bi bi-pencil-square px-2" onClick={handleEditProject}></i>
                                <i className="bi bi-trash3-fill" onClick={handleRemoveProject}></i>
                            </>
                        }
                    </div>
                </div>
                <p className="card-text" style={{ padding: '5px 15px' }}>
                    <div className='d-flex flex-row justify-content-between px-2'><span style={{ fontSize: '16px', fontWeight: '500', marginTop: '2px' }}>Zipcode</span> {zip_code} <br /></div>
                    <div className='d-flex flex-row justify-content-between px-2'><span style={{ fontSize: '16px', fontWeight: '500', marginTop: '2px' }}>Gross Area</span> {gross_area}<br /></div>
                    <div className='d-flex flex-row justify-content-between px-2'><span style={{ fontSize: '16px', fontWeight: '500', marginTop: '2px' }}>Work Type</span> {work_type} <br /></div>
                    <div className='d-flex flex-row justify-content-between px-2'><span style={{ fontSize: '16px', fontWeight: '500', marginTop: '2px' }}>Main Building Use</span> {main_building_use}</div>
                </p>
            </div>
        </div>
    );
};

export default ProjectCard;
