import { SAVE_TOKEN, REMOVE_TOKEN } from "../Constants/actionTypes.jsx";

const initialState = {
  token: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SAVE_TOKEN:
      
   
      return Object.assign({}, state, {
        token: action.payload,
      });
    case REMOVE_TOKEN:
      return Object.assign({}, state, {
        token: action.payload,
      });
    default:
      return state;
  }
};
