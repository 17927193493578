import {
  SHOW_MESSAGE,
  UPDATE_MESSAGE,
} from "../Constants/actionTypes.jsx";

const showGlobalMessage = (data) => {
  return {
    type: SHOW_MESSAGE,
    payload: data,
  };
};

const updateGlobalMessage = (data) => {  
  return {
    type: UPDATE_MESSAGE,
    payload: data,
  };
};

export { showGlobalMessage, updateGlobalMessage };
