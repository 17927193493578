import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import GetInTouchForm from "./GetInTouchForm";
import { Button, Grid, styled, Typography } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
// const styles = styled((theme) => ({

//   footerBanner: {
//     backgroundColor: "#1a67a3", //"#1a67a3",
//   },
//   responsiveGridFirst: {
//     [theme.breakpoints.down("sm")]: {
//       textAlign: "center",
//     },
//   },
//   responsiveGridLast: {
//     textAlign: "end",
//     [theme.breakpoints.down("sm")]: {
//       textAlign: "center",
//     },
//   },
//   blobBtn: {
//     textAlign: "center",
//     fontSize: "15px",
//     fontWeight: "500",
//     color: "#fcc75c",
//     padding: "9px 16px",
//     lineHeight: "normal",
//     borderRadius: "7px",
//     width: "auto",
//     margin: "0",
//     border: "solid 1px #fcc75c",
//     "&:hover": {
//       // transform: " translateZ(0) scale(1.1)",
//       // transition: "transform 0.45s",
//       background: "#fcc75c",
//       transition: "0.3s",
//       color: "#1a67a3", //1a67a3",
//     },
//   },
//   socialLinkBtn: {
//     color: "#b0b3b8",
//     fontSize: "17px",
//     background: "#201e3e",
//     width: "30px",
//     height: "30px",
//     padding: "5px 3px",
//     borderRadius: "5px",
//     display: "block",
//     lineHeight: "normal",
//     "&:hover": {
//       color: "white",
//     },
//   },
//   socialBox: {
//     display: "flex",
//     flexDirection: "row",
//     gap: 10,
//     textAlign: "center",
//     justifyContent: "center",
//     listStyle: "none"

//   },
//   textWhite: {
//     color: "white",
//   },
// }));
import './index.css'
import { BASE_URL, CLIENT_PROPAGILE_URL, CLIENT_TEAM_URL } from "../../Constants/serverConfig";
import { Modal } from "react-bootstrap";
import axios from "axios";
import Feedback from "./Feedback";
const Footer = ({ showModalFeedback, setShowModalFeedback }) => {
  //const classes = styles();

  const initialFormData = {
    name: '',
    email: '',
    zipcode: '',
    comment: '',
    feedbackType: '',
    captcha: ''
  }
  const [openGetInTouchModal, setOpenGetInTouchModal] = useState(false);
  const [show, setShow] = useState(false);

  const mystyle = {
    cursor: "pointer"
  }

  return (
    <>
      {/* Modal Contact us */}

      {show &&
        <Feedback showModal={show} setShowModal={setShow} showModalFeedback={showModalFeedback} setShowModalFeedback={setShowModalFeedback}></Feedback>
      }
      <Grid
        container
        className="footer-grid"
        style={{}}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          style={{}}
          className={'responsiveGridFirst'}
        >
          {/* <Button
            onClick={() => setOpenGetInTouchModal(true)}
            className='blob-btn'
          >
            Get in Touch
          </Button> */}
          <span style={mystyle} className="d-flex flex-row align-items-center justify-content-center navbar-brand">
            {/* <!-- Logo Image --> */}
            <img src={CLIENT_PROPAGILE_URL + "/logo.png"} alt="" className="d-inline-block align-middle  img-header" />
            {/* <!-- Logo Text --> */}
            <div className='d-flex flex-column justify-content-between logo-text'>
              <span className="tittle-company text-left">PropAgile</span>
              <small className="">Building Construction Frameworks</small>
            </div>
          </span>
          <p className="small-footer mb-0 text-light">&copy; Copyrights. All rights reserved. <a className="text-primary text-warning" href={CLIENT_PROPAGILE_URL}>propagile.com</a></p>
          <p className="small-footer mb-0 mt-3 text-light"><a href={CLIENT_PROPAGILE_URL + "/terms"}>Conditions of Use</a></p>
          <p className="small-footer mb-0 mt-3 text-light"><a href={CLIENT_PROPAGILE_URL + "/privacy-policy"}>Privacy Notice</a></p>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          style={{
            alignSelf: "center",
          }}
        >

          <div className="text-center useful-links mt-3 mt-md-0">
            <h5 className="text-light">Useful Links</h5>
            <p className="small-footer" style={{ cursor: 'pointer' }} onClick={() => { setShow(!show) }}>Contact Us</p>
            <p className="small-footer" style={{ cursor: 'pointer' }} onClick={() => { window.location.href = "/" }}>Home</p>
            <p className="small-footer d-none d-md-block" style={{ cursor: 'pointer' }}>&nbsp;</p>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          className={'responsiveGridLast'}
        >
          {/* <Typography className={'textWhite'}>
            <span className={'textWhite'}>
              {moment().format("YYYY")} Propagile{" "}
            </span>{" "}
            /{" "}
            <Link className={'textWhite'} to="/terms">
              Terms of Use
            </Link>{" "}
            /
            <Link className={'textWhite'} to="/privacy-policy">
              Privacy Policy
            </Link>
          </Typography> */}
          <div className='text-center social-links-footer'>
            <h5 className="text-white mb-3 text-center">Follow Us On</h5>
            <div className='d-flex flex-row justify-content-center' >
              <a href="https://www.youtube.com/@ConstructionConnections" target="_blank"><img src='https://propagile.com/images/youtube.png' width={150}></img></a>
            </div>
          </div>
        </Grid>
      </Grid>
      {!!openGetInTouchModal && (
        <GetInTouchForm
          openGetInTouchModal={openGetInTouchModal}
          handleCloseModal={() => setOpenGetInTouchModal(false)}
        />
      )}
    </>
  );
};
export default Footer;
