import React from 'react';

const Button = ({ label, onClick, type = 'button', variant = 'primary' }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`btn btn-${variant} ml-0 ml-md-2`}     
    >
      {label}
    </button>
  );
};

export default Button;
