import React, { useState, useEffect } from 'react'
import NotificationCard from '../Atoms/NotificationCard'

function NotificationLayout({ notifications }) {
    const [notificationsList, setNotificationsList] = useState([{ id: 1, name: 'notification', description:"There are no new notifications." }])

    // useEffect(() => {
    //     setNotificationsList(notifications)
    // }, [notifications])

    return (
        <div className='container-notification'>
            {
                notificationsList && notificationsList.map((notification, index) => (
                    <div className='container-notification-item'>
                        <NotificationCard
                            {...notification}
                        />
                    </div>

                ))}




        </div>
    )
}

export default NotificationLayout